export const getFile = async (url, accessData, setErrorMessage) => {
    if (!url) return null;
    try {
      const resp = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/ms-excel",
          'Authorization': 'Bearer ' + accessData,
        },
      });
      if (resp.ok) {
          const fileData = await resp.blob();
          // Convert fileData to dataUrl and resolve
          return fileData;
      } else {
        handleErrorMessage(setErrorMessage)
        return null;
      }

    } catch (error) {
      handleErrorMessage(setErrorMessage)
      return null;
    }
};

const handleErrorMessage = async (setErrorMessage) => {
  if (setErrorMessage) setErrorMessage('A kért tartalom nem elérhető');
};

export const downloadFile = (blob, filename) => {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
    link.remove();
};
