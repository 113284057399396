import create from 'zustand';
import { persist } from "zustand/middleware"

let store = (set) => ({
    threadList: null,
    setThreadList:  (data) => {
        set({ threadList: data })
    },
    addThread: (newThread) => set(state => ({
        threadList: [...state.threadList, newThread]
    })),
    updateThread: (updatedThread) => set(state => ({
        threadList: state.threadList.map((thread) => {
            if(thread.id === updatedThread.id) {
                return updatedThread;
            } else {
                return thread;
            }
        })
    })),
    // removeThread: (id) =>set(state => ({
    //     threadList: state.threadList.filter((thread) => thread.id !== id)
    // })),
});

store = persist(store, { name: "threadStore" });

const useThreadStore = create(store);

export default useThreadStore;
