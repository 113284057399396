import * as React from 'react';
import { FilledButton } from "../../styles/ButtonStyles";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import './style.css';

// Stores
import useDialogStore from '../../stores/useDialogStore';

export default function WarningDialog({description, setOpen}) {
  const dialogStore = useDialogStore();

  return (
    <div>
      <Dialog
        open={true}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{textAlign:'center', backgroundColor: 'white', color: '#00000099'}}>
        <ErrorOutlineIcon sx={{fontSize: 60}} color="warning"/>
        <p className='error-title'>
            Figyelem!
        </p>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{textAlign: 'center'}}>
            <span className="content">{description}</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{justifyContent: 'center'}}>
          <FilledButton onClick={() => setOpen(false)} color="warning" variant="contained" sx={{width: "100px", backgroundColor: '#ed6c02', marginBottom: '10px', "&:hover": {color: '#ed6c02'}}}>Mégse</FilledButton>
          <FilledButton onClick={() => {setOpen(false); dialogStore.setIsConfirmed(true)}} color="warning" variant="contained" sx={{width: "100px", backgroundColor: '#ed6c02', marginBottom: '10px', "&:hover": {color: '#ed6c02'}}}>Rendben</FilledButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}