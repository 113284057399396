import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { DesignedLabel } from '../../../styles/LabelStyles';
import './style.css';

export default function CheckboxList({ listItems, handleData, label }) {

    const handleChange = (event, item) => {
        handleData(event.target.checked, item)
      };

    return (
        <div className='checkboxlist'>
            <DesignedLabel sx={{marginLeft: '5%'}}>{label}</DesignedLabel>
            <FormGroup row sx={{paddingLeft: '5%', marginTop: '3px'}}>
                {listItems && listItems.map((item, i) => (
                    <FormControlLabel
                        key={i}
                        control={
                            <Checkbox
                                sx={{'&.Mui-checked': {color: '#00B4D8'}}}
                                defaultChecked size='small'
                                onChange={(event) => handleChange(event, item)}
                            />
                        } 
                        label={item.name} />
                ))}
            </FormGroup>
        </div>
    );
}
