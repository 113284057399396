import { styled } from "@mui/material/styles";
import MuiButton from "@mui/material/Button";

export const FilledButton = styled(MuiButton)(() => ({
    color: 'white',
    borderColor: 'white',
    backgroundColor: '#00B4D8',
    padding: '5px 10px',
    "&:hover": {borderColor: '#00B4D8', backgroundColor: 'white', color: '#00B4D8', "& .icon": {color: '#00B4D8'}},
}));

export const IconButton = styled(MuiButton)(() => ({
    color: 'white',
    borderColor: '#00B4D8',
    backgroundColor: '#00B4D8',
    textAlign: 'center',
    height: '25px',
    minWidth: '25px',
    padding: '0px',
    marginRight: '10px',
    "&:hover": {borderColor: '#00B4D8', backgroundColor: 'white', color: '#00B4D8', "& .icon": {color: '#00B4D8'}},
}));