import React from 'react';
import { useEffect, useContext, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { datagridLocalization, fetchData } from '../../utils/requestUtils';
import { useNavigate } from 'react-router-dom';
import useProjectStore from "../../stores/useProjectStore";
import useFileStore from "../../stores/useFileStore";
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment/moment';
import ErrorMessage from '../ErrorMessage';
import useViewWidthStore from '../../stores/useViewWidthStore';
import Switch from '../Switch';
import { URL } from '../../constans';
import './style.css';

// Icons
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import AuthContext from '../../context/AuthContext';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import BarChartIcon from '@mui/icons-material/BarChart';

export default function ProjectsTable({setTitle}) {
    const { authTokens } = useContext(AuthContext);
    const navigate = useNavigate();
    const projectStore = useProjectStore();
    const fileStore = useFileStore();
    const [showTable, setShowTable] = useState(false)
    const [errorMessage, setErrorMessage] = useState("");
    const updateViewWidth = useViewWidthStore((state) => state.updateViewWidth);
    const [listAllProjects, setListAllProjects] = useState(false);
    
    const columns = [
        {
          field: "warning", headerName: "", width: 50, sortable: false,
          renderCell: (params) => {
            const isAppFileMissing = () => {
              const thisRow = getCurrentRow(params);
              let isMissing = false;
              if (thisRow.role === "applicant") {
                const hasNoAppFile = thisRow.projectfile_set.filter(file => file.projectapplicationfile_set.length === 0)
                if (hasNoAppFile.length > 0) {
                  isMissing = true;
                }
              }
              return isMissing;
            };
            
            if (isAppFileMissing()) return (
              <>
                <Tooltip title="Nincs minden fájlhoz feltöltve ajánlat">
                  <ReportGmailerrorredIcon className="icon" sx={{ color: 'red', m: '5px', mr: '5px' }} />
                </Tooltip>
              </>
            )
          
          }
        },
        { field: 'id', headerName: '', hide: true },
        { field: 'name', headerName: 'Projekt neve', sortable: true, flex: 1 },
        { field: 'due_date', headerName: 'Határidő', maxWidth: 140, sortable: true, flex: 1,
        valueFormatter: (params) => {
          const formattedDueDate = moment(params.value).format('YYYY-MM-DD hh:mm');
          return formattedDueDate}
        },
        { field: 'description', headerName: '', hide: true },
        { field: 'role', headerName: 'Szerepkör', maxWidth: 130, sortable: true, flex: 1 },
        { field: 'projectfile_set', headerName: '', hide: true },
        { field: 'is_active', headerName: '', hide: true },
        { field: 'state', headerName: '', hide: true },
        { field: 'handlers', headerName: '', hide: true },
        {
          field: "statistics", headerName: "", maxWidth: 50, sortable: false, flex: 1,
          renderCell: (params) => {
            const loadStatistics = async (e) => {
              e.stopPropagation(); // don't select this row after clicking
              const thisRow = getCurrentRow(params);
              projectStore.setSelectedProject(thisRow);
              if (thisRow.projectfile_set.length === 0) {
                setErrorMessage('A projekthez nincs feltöltve árazatlan költségvetés.')
              } else {
                navigate(`/dashboard/${thisRow.id}`);
              }
            }; 
            const isMaintainer = () => {
              const thisRow = getCurrentRow(params);
              return thisRow.role === "maintainer";
            }
            if (isMaintainer()) return (
              <BarChartIcon className="icon" onClick={(e) => {loadStatistics(e)}} sx={{ color: '#00B4D8', m: '5px', mr: '10px' }} />
            )
          }
      },
        {
            field: "details", headerName: "", maxWidth: 60, sortable: false, flex: 1,
            renderCell: (params) => {
              const loadData= async (e) => {
                e.stopPropagation(); // don't select this row after clicking
                const thisRow = getCurrentRow(params);
                loadProject(thisRow);
              }; 
              return (
                <DoubleArrowIcon className="icon" onClick={(e) => {loadData(e)}} sx={{ color: '#00B4D8', m: '5px', mr: '10px' }} />
              )
            }
        },
    ]
    
    const getCurrentRow = (params) => {
        const api = params.api;
        const thisRow = {}; 
        const columns = api.getAllColumns().filter((c) => (c.field !== "details" && c.field !== "warning" && c.field !== "statistics"));
        for (const c of columns) {
          thisRow[c.field] = params.getValue(params.id, c.field);
        }
        return thisRow;
    }

    const loadProject = (project) => {
      projectStore.setSelectedProject(project);
      if (project.role === "maintainer") {
        navigate(`/project-details-maintainer/${project.id}`);
      } else {
        navigate(`/project-details-applicant/${project.id}`);
      }
    }

    useEffect(() => {
        fetchProjects(listAllProjects);
        cleanFileStore();
        updateViewWidth('60%');
      },[])

    const cleanFileStore = () => {
        fileStore.setServerResp(null);
        fileStore.setMainCategories(null);
        fileStore.setTargetSheetsCheckList(null);
        fileStore.setSelectedSheetsData(null);
        fileStore.setFileIsChanged(false);
        fileStore.setPrevProjectFileId(null);
    }

      const fetchProjects = async (listAll) => {
        const data = await fetchData(`${URL}/api/projects?all=${listAll}`, projectStore.setProjectsData, authTokens.access, setErrorMessage);
        if (data !== "err" && !data.error) {
          if (data.data.projects.length === 1) {
            loadProject(data.data.projects[0])
          } else {
            setShowTable(true);
          }
        }
      }

    return ( 
      <>
        <ErrorMessage errorMessage={errorMessage} setErrorMessage={setErrorMessage}/>
        {showTable &&
        <>
          <div style={{ height: 450, width: '100%' }}>
              <DataGrid
                  localeText={datagridLocalization()}
                  rows={projectStore.projectsData}
                  columns={columns}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  disableColumnSelector
                  getRowClassName={(params) => `active-${params.row.is_active}`}
              />
          </div>
          <div style={{padding: '5px 10px 10px 10px'}}>
            <Switch handleData={() => {fetchProjects(!listAllProjects); setListAllProjects(listAllProjects => !listAllProjects)}} leftText="aktív projektek" rightText="összes projekt" />
          </div>
        </>
        }
      </>
    )
}
