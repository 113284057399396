import React, { useState, useContext } from 'react';
import TextField from '@mui/material/TextField';
import { FilledButton } from '../../styles/ButtonStyles';
import { dataRequest } from '../../utils/requestUtils';
import AuthContext from '../../context/AuthContext';
import useThreadStore from '../../stores/useThreadStore';
import { URL } from '../../constans';
import './style.css';

export default function ThreadCommentCreate({ threadId, setErrorMessage }) {
  const { authTokens } = useContext(AuthContext);
  const [commentText, setCommentText] = useState("");
  const threadStore = useThreadStore();

  const addComment = async () => {
    const data = {"thread_id": threadId, "text": commentText};
    const resp = await dataRequest(`${URL}/api/threads/comments/`, 'POST', data, authTokens.access, setErrorMessage);
    if (resp !== "err" && !resp.error) {
        const updatedThread = threadStore.threadList.find(thread => thread.id === threadId);
        updatedThread.comment_set.push(resp.data.comment);
        threadStore.updateThread(updatedThread);
        setCommentText("");
    }
  }

  return (
    <div className="thread-comment-create">
      <div className="thread-comment-create-header">
        <h3>Komment hozzáadása</h3>
      </div>
      <div className="thread-comment-create-content">
        <TextField onChange={(e) => setCommentText(e.target.value)} value={commentText} minRows={3} multiline variant="outlined" color="info" label="Komment.." sx={{width: "100%"}}/>
      </div>
      <div className="thread-comment-create-control">
        <FilledButton variant="outlined" onClick={async () => await addComment()}>
          Hozzáadás
        </FilledButton>
      </div>
    </div>
  );
}
