import React, { useState } from 'react'
import TextField from '@mui/material/TextField';
import Switch from '../../Switch';
import { IconButton } from '../../../styles/ButtonStyles';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import './style.css';

// Icons
import CachedIcon from '@mui/icons-material/Cached';

export default function PlotPercentInputs({ handleData, statLines, selectedFunction, setSelectedFunction }) {
    const [bottomPercent, setBottomPercent] = useState(null);
    const [topPercent, setTopPercent] = useState(null);
    const [isInverse, setIsInverse] = useState(false);

    const refreshData = () => {
        if (bottomPercent && topPercent) {
            handleData(isInverse, parseFloat(topPercent), parseFloat(bottomPercent));
        }
    }

    const handleIsInverse = () => {
        setIsInverse(isInverse => !isInverse);
    }

    const handleFunctionChange = (e) => {
        setSelectedFunction(e.target.value);
      };

  return (
    <div className='plotpercentinputs'>
        <div className="plotpercentinputs-header">
            <p>Eltérés</p>
        </div>
        <div className='plotpercentinputs-content'>
                <FormControl sx={{width: '25%'}}>
                    <InputLabel id="select-label">Viszonyítási alap</InputLabel>
                    <Select
                        labelId="select-label"
                        value={selectedFunction}
                        color="info"
                        label="Viszonyítási alap"
                        onChange={handleFunctionChange}
                        sx={{display: 'inline-block', marginRight: '5px', marginBottom: '5px', height: '59px'}}
                    >
                    {statLines &&
                    statLines.map((line, i) => (
                        <MenuItem key={i} value={line}>{line.name}</MenuItem>
                    )) 
                    }
                    </Select>
                </FormControl>
            <div className='plotpercentinputs-container'>
                <div className='plotpercentinputs-subcontainer'>
                    <div>
                        <TextField onChange={(e) => setTopPercent(e.target.value)} label="Felső %" variant="standard" sx={{width: '100px', marginLeft: '10px', marginRight: '10px'}} />
                        <TextField onChange={(e) => setBottomPercent(e.target.value)} label="Alsó %" variant="standard" sx={{width: '100px', marginRight: '10px'}} />
                    </div>
                    <div>
                        <Switch handleData={handleIsInverse} leftText="" rightText="inverz" />
                        <IconButton variant="outlined" sx={{marginLeft: '20px'}}>
                            <CachedIcon onClick={refreshData} />
                        </IconButton> 
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}