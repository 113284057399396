import React from 'react'
import ProjectFile from '../ProjectFile';
import ProjectApplicantFile from '../ProjectApplicantFile';
import useProjectStore from '../../stores/useProjectStore';
import UploadFile from '../UploadFile';
import { URL } from '../../constans';
import './style.css';

// Icons
import AttachFileIcon from '@mui/icons-material/AttachFile';

export default function FileContainer({ 
    file, projectFileInactive = false, projectFileChange = false, editAppFile = false, setErrorMessage = null
}) {
    const selectedProject = useProjectStore((state) => state.selectedProject);

    return (
        <>
            <ProjectFile file={file} inactiveIcon={projectFileInactive} changeIcon={projectFileChange} setErrorMessage={setErrorMessage}/>
            {
                (selectedProject.role === "applicant" && file.projectapplicationfile_set[0]) ? (
                    <ProjectApplicantFile file={file} editAppFile={editAppFile} setErrorMessage={setErrorMessage}/>
                ) : (
                    (selectedProject.role === "applicant") ?
                        (
                            <div className='no-file-container'>
                                <AttachFileIcon sx={{color: "rgba(115, 120, 121, 0.5)"}}/>
                                <p>Nincs fájl feltöltve</p>
                                {editAppFile === true && 
                                    <UploadFile api={`${URL}/api/projects/applications/files/`} projectFileId={file.id} setErrorMessage={setErrorMessage}/>
                                }
                            </div>
                        ) : (
                            <></>
                        )
                )
            }
        </>
    )
}
