import * as React from 'react';
import Link from '@mui/material/Link';
import "./style.css";

export default function Breadcrumb({ links, refetchData, setBreadcrumbLinks, setCurrTab }) {

  const refreshBreadcumbLinks = (link) => {
    const index = links.findIndex(currLink => currLink.category_name === link.category_name);
    const newLinks = links.slice(0,index + 1);
    setBreadcrumbLinks(newLinks);
    setCurrTab(newLinks[newLinks.length-1]);
  }

  return (
    <div role="presentation" className='breadcrumb'>
      {links.map((link, i) => (
          <Link key={i} component="button" underline="hover" className='breadcrumb-link' sx={{marginRight: '5px'}} color={(i === links.length-1) ?  "#00B4D8" : "#666666"} onClick={() => {refetchData(link); refreshBreadcumbLinks(link)}}>
            {link.category_name} /
          </Link> 
      ))}
    </div>
  );
}