import React, { useState, useContext, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { FilledButton } from '../../styles/ButtonStyles';
import { dataRequest } from '../../utils/requestUtils';
import AuthContext from '../../context/AuthContext';
import useThreadStore from '../../stores/useThreadStore';
import useProjectStore from '../../stores/useProjectStore';
import Dropdown from '../Dropdown';
import { URL } from '../../constans';
import './style.css';

export default function ThreadCreate({ projectId, setErrorMessage, setIsClosed }) {
  const { authTokens } = useContext(AuthContext);
  const [threadText, setThreadText] = useState("");
  const [threadSubject, setThreadSubject] = useState("");
  const [threadTarget, setThreadTarget] = useState("");
  const threadStore = useThreadStore();
  const selectedProject = useProjectStore((state) => state.selectedProject);
  const [dropdownContent, setdropdownContent] = useState(null);

  useEffect(() => {
    let allOptions = [{id: 'project', name: "Projekt", type: ""}];
    if (selectedProject.projectfile_set) {
      for (const projectFile of selectedProject.projectfile_set) {
          allOptions.push({id: projectFile.id, name: projectFile.filename, type: 'project-file'});
          if (projectFile.projectapplicationfile_set) {
            for (const appFile of projectFile.projectapplicationfile_set) {
                allOptions.push({id: appFile.id, name: appFile.filename, type: 'project-app-file'});
            }
          }
      }
    }
    setdropdownContent(allOptions);
    setThreadTarget(allOptions[0]); // set project to be the default thread target
  },[])

  const addThread = async () => {
    let data = {"project_id": projectId, "text": threadText, "subject": threadSubject};
    if (threadTarget.id !== "project") {
        const fileType = threadTarget.type === 'project-file' ? "project_file_id" : "project_application_file_id";
        data = { ...data, [fileType]: `${threadTarget.id}`};
    }
    const resp = await dataRequest(`${URL}/api/threads/`, 'POST', data, authTokens.access, setErrorMessage);
    if (resp !== "err" && !resp.error) {
        threadStore.addThread(resp.data.thread);
        setIsClosed(true)
    }
  }

  return (
    <div className="thread-create">
      <div className="thread-create-header">
        <h3>Thread hozzáadása</h3>
      </div>
      <div className="thread-create-content">
        <Dropdown targets={dropdownContent} selectedTarget={threadTarget} setSelectedTarget={setThreadTarget}/>
        <TextField onChange={(e) => setThreadSubject(e.target.value)} value={threadSubject} multiline variant="outlined" color="info" label="Tárgy megadása" sx={{width: "100%", marginTop: '10px'}}/>
        <TextField onChange={(e) => setThreadText(e.target.value)} value={threadText} minRows={3} multiline variant="outlined" color="info" label="Komment.." sx={{width: "100%", marginTop: '10px'}}/>
      </div>
      <div className="thread-create-control">
        <FilledButton sx={{marginRight: '10px'}} variant="outlined" onClick={async () => await addThread()}>
          Hozzáadás
        </FilledButton>
        <FilledButton variant="outlined" onClick={() => setIsClosed(true)}>
          Mégse
        </FilledButton>
      </div>
    </div>
  );
}
