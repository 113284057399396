import * as React from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function Dropdown({ targets, selectedTarget, setSelectedTarget }) {

  const handleChange = (e) => {
    setSelectedTarget(e.target.value);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <FormControl fullWidth>
        <Select
          value={selectedTarget}
          color="info"
          label="Target"
          onChange={handleChange}
        >
        {targets &&
           targets.map(target => (
               <MenuItem key={target.id + target.name} value={target}>{target.name}</MenuItem>
           )) 
        }
        </Select>
      </FormControl>
    </Box>
  );
}