import React from 'react';
import { useEffect, useState, useContext, useLayoutEffect } from 'react';
import ParallelTabs from '../ParallelTabs';
import Breadcrumb from '../../Breadcrumb';
import { DesignedLabel } from '../../../styles/LabelStyles';
import Plot from 'react-plotly.js';
import AuthContext from '../../../context/AuthContext';
import { fetchData } from '../../../utils/requestUtils';
import useFileStore from '../../../stores/useFileStore';
import Switch from '../../Switch';
import RadioButtonGroup from '../RadioButtonGroup';
import './style.css';
import AppFilesSelect from '../AppFilesSelect';
import ErrorMessage from '../../ErrorMessage';
import { URL } from '../../../constans';

export default function BarChart({ projectId, target, direction }) {
    const { authTokens } = useContext(AuthContext);
    const [errorMessage, setErrorMessage] = useState("");
    const [categories, setCategories] = useState([]);
    const [currTab, setCurrTab] = useState(null);
    const [breadcrumbLinks, setBreadcrumbLinks] = useState([{category_name: 'Projekt'}]);
    const [projectApplicants, setProjectApplicants] = useState(null);
    const fileStore = useFileStore();
    const [isMixedSourcesAllowed, setIsMixedSourcesAllowed] = useState(false);
    const [priceType, setPriceType] = useState("total");
    const [traces, setTraces] = useState([]);
 
    const data = traces;
    const layout = {barmode: 'group'};

    useLayoutEffect(() => {
        if (target) {
            loadData();
        }
    },[target])

    const loadData = async () => {
        let reqURL = `${URL}/api/projects/applications/files?project_id=${projectId}`;
        const resp = await fetchData(reqURL, false, authTokens.access, setErrorMessage);

        if (resp !== "err" && !resp.error) {
            setProjectApplicants(resp.data.applications);
            let defProjectAppFileIds = [];
            for (const application of resp.data.applications) {
                if (application.project_application_files.length > 0) {
                    defProjectAppFileIds.push(application.project_application_files[0].project_application_file_id);
                }
            }

            fileStore.setSelectedAppFileIds(defProjectAppFileIds);
            fetchPlotData(defProjectAppFileIds);
        }
    }

    useEffect(() => {
        handleDataChanges();
    },[currTab])

    const handleDataChanges = async () => {
        if (currTab) {
            const resp = await refreshPlot(currTab);
            if (resp !== "err" && !resp.error) {
                if (!breadcrumbLinks.some(link => link.category_name === currTab.category_name)) {
                    setBreadcrumbLinks([...breadcrumbLinks, currTab])
                }
            }
        }
    }

    const refreshPlot = async (currTab, refreshedPriceType = priceType) => {
        let reqURL = `${URL}/api/stats/${direction}?project_id=${projectId}`
            + `&project_file_id=${target.id}`
            + `&project_application_file_ids=${fileStore.selectedAppFileIds.join(",")}`
            + `&price=${refreshedPriceType}`
            + `&allow_mixed_sources=${!isMixedSourcesAllowed}`;

        if (currTab && currTab.ceh_id) {
            reqURL += `&ceh_id=${currTab.ceh_id}`;
        }
        const resp = await fetchData(reqURL, false, authTokens.access, setErrorMessage);
        if (resp !== "err" && !resp.error) {
            if (currTab && currTab.category_name) setCategories([{category_name: `${currTab.category_name}`}, ...resp.data.categories])
            setTraces(resp.data.traces);
        }
        return resp;
    }

    const fetchPlotData = async (defProjectAppFileIds) => {
        const resp = await fetchData(
            `${URL}/api/stats/${direction}?project_id=${projectId}`
                + `&project_file_id=${target.id}`
                + `&project_application_file_ids=${defProjectAppFileIds.join(",")}`
                + `&price=${priceType}`
                + `&allow_mixed_sources=${isMixedSourcesAllowed}`,
            false,
            authTokens.access,
            setErrorMessage
        );
        if (resp !== "err" && !resp.error) {
            setCategories([{category_name: 'Projekt'}, ...resp.data.categories]);
            setTraces(resp.data.traces);
        }
    }
    
    const handleExtremeCherrypick = async () => {
        await refreshPlot(currTab);
        setIsMixedSourcesAllowed(isMixedSourcesAllowed => !isMixedSourcesAllowed)
    }

    const handlePriceTypeChange = async (refreshedPriceType) => {
        await refreshPlot(currTab, refreshedPriceType);
        setPriceType(refreshedPriceType)
    }
    
  return (
    <div className="barchart">
        <div className="barchart-header">
            <ParallelTabs allTabs={categories} setData={(data) => {setCurrTab(data)}}/>
            {breadcrumbLinks.length > 0 &&
                <Breadcrumb sx={{paddingTop: '30px'}} links={breadcrumbLinks} refetchData={refreshPlot} setBreadcrumbLinks={setBreadcrumbLinks} setCurrTab={setCurrTab} />
            }
            <ErrorMessage errorMessage={errorMessage} setErrorMessage={setErrorMessage} />
        </div>
        <div className="barchart-content">
            <div className='barchart-config'>
                <DesignedLabel sx={{marginLeft: '5%'}}>Cherry-pick</DesignedLabel>
                <Switch handleData={handleExtremeCherrypick} leftText="min(A+D)" rightText="min(A) + min(D)" />
                <RadioButtonGroup
                    labelName="Ártípus"
                    controlItems={[{value: "total", name: "Teljes ár (A+D)"}, {value: "material", name: "Anyagár (A)"}, {value: "fee", name: "Munkadíj (D)"}]}
                    handleData={handlePriceTypeChange}
                />
            </div>
            <Plot
                data={data}
                layout={layout}
                useResizeHandler
                className='barchart-plot'
            />
        </div>
        <div className="barchart-control">
            {projectApplicants &&
                <AppFilesSelect projectApplicants={projectApplicants} handleData={() =>{fetchPlotData(fileStore.selectedAppFileIds)}} />
            }
        </div>
    </div>
  )
}
