import React from 'react';
import { useState, useEffect, useContext } from 'react';
import AuthContext from '../../context/AuthContext';
import { fetchData } from '../../utils/requestUtils';
import Thread from '../Thread';
import ErrorMessage from '../ErrorMessage';
import ThreadCreate from '../ThreadCreate';
import { IconButton } from '../../styles/ButtonStyles';
import useThreadStore from '../../stores/useThreadStore';
import useProjectStore from '../../stores/useProjectStore';
import { URL } from '../../constans';
import './style.css';

// Icons
import AddIcon from '@mui/icons-material/Add';

export default function ThreadList({ projectId }) {
  const { authTokens } = useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState("");
  const [isAddThreadBtnShowed, setIsAddThreadBtnShowed] = useState(true);
  const threadStore = useThreadStore();
  const selectedProject = useProjectStore((state) => state.selectedProject);

  useEffect(() => {
      fetchThreads()
  },[])

  const fetchThreads = async () => {
    const serverResp = await fetchData(`${URL}/api/threads?project_id=${projectId}`, null, authTokens.access, setErrorMessage);
    if (serverResp !== "err" && !serverResp.error) {
      threadStore.setThreadList(serverResp.data.threads);
    }
  }

  return (
    <div className="thread-list">
      <ErrorMessage errorMessage={errorMessage} setErrorMessage={setErrorMessage}/>
      {selectedProject.role === "maintainer" &&
        <>
          {
            isAddThreadBtnShowed ?
              (
                  <div className="add-thread">
                    <IconButton variant="outlined" sx={{marginLeft: "11%"}} onClick={() => {setIsAddThreadBtnShowed(false)}} data-testid="addThreadId">
                      <AddIcon className="add-icon"/>
                    </IconButton>
                    Thread hozzáadása
                  </div>
              ):
              (
                <ThreadCreate projectId={projectId} setErrorMessage={setErrorMessage} setIsClosed={setIsAddThreadBtnShowed}/>
              )
          }
        </>
      }
      <div className="thread-list-content">
        <ul>
          { threadStore.threadList &&
            threadStore.threadList.map(thread => 
              <li key={thread.id} data-testid="threadId">
                <Thread thread={thread}/>
              </li>
            )
          }
        </ul>
      </div>
    </div>
  );
}
