import React from 'react';
import ThreadComment from '../ThreadComment/ThreadComment';
import ThreadCommentCreate from '../ThreadCommentCreate';
import useProjectStore from '../../stores/useProjectStore';

export default function ThreadCommentList({ threadId, commentList, isActive, setErrorMessage }) {
  const selectedProject = useProjectStore((state) => state.selectedProject);

  return (
    <div className="thread-comment-list">
      <div className="thread-comment-list-content">
        <ul>
          { commentList &&
            commentList.map((comment, index) => 
              <li key={index}>
                <ThreadComment
                  index={index}
                  user={comment.owner}
                  text={comment.text}
                  createdAt={comment.created_at}
                  isActive={isActive}
                />
              </li>
            )
          }
        </ul>
        {isActive && selectedProject.role === "maintainer" &&
          <ThreadCommentCreate threadId={threadId} setErrorMessage={setErrorMessage} />
        }
      </div>
    </div>
  );
}
