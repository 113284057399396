import React, {useState, useContext} from 'react';
import { dataRequest } from '../../utils/requestUtils';
import AuthContext from '../../context/AuthContext';
import moment from 'moment/moment';
import useProjectStore from '../../stores/useProjectStore';
import { URL } from '../../constans';
import './style.css'

// icons
import PortraitSharpIcon from '@mui/icons-material/PortraitSharp';
import EventSharpIcon from '@mui/icons-material/EventSharp';
import ForumSharpIcon from '@mui/icons-material/ForumSharp';
import ExpandMoreSharpIcon from '@mui/icons-material/ExpandMoreSharp';
import CloseIcon from '@mui/icons-material/Close';

export default function ThreadSummary({ thread, isCommentListShowed, setIsCommentListShowed, setIsActive, setErrorMessage }) {
  const { authTokens } = useContext(AuthContext);
  const [isCurrActive, setIsCurrActive] = useState(thread.is_active);
  const selectedProject = useProjectStore((state) => state.selectedProject);

  const updateThread = async (currVal) => {
    setIsCommentListShowed(false);
    setIsActive(active => !active);
    const data = {"is_active": currVal};
    const resp = await dataRequest(`${URL}/api/threads/${thread.id}/`, 'PUT', data, authTokens.access, setErrorMessage);
    if (resp !== "err" && !resp.error) {
      setIsCurrActive(isCurrActive => !isCurrActive)
    }
  }

  return (
    <div className="thread-summary">
      <div className='thread-summary-header'>
        <div className="target-container">
          <p className='target'>{thread.target}</p>
          {selectedProject.role === "maintainer" &&
            <CloseIcon fontSize="small" className={`close-thread ${isCurrActive ? "opened" : "closed"}`} onClick={async () => {await updateThread(!isCurrActive)}} />
          }
        </div>
        <p className={`subject ${isCurrActive ? "" : "dark-closed"}`}>{thread.subject}</p>
      </div>
      <div className={`thread-summary-content ${isCurrActive ? "" : "closed-thread-summary"}`}>
        <ul>
          <li>
              <ExpandMoreSharpIcon className={`${isCurrActive ? "opened" : "dark-closed"} icon`} onClick={() => {setIsCommentListShowed(isCommentListShowed => !isCommentListShowed)}} />
              {isCommentListShowed ? "Eltűntetés" : "Megjelenítés"}
          </li>
          <li><PortraitSharpIcon fontSize="small" className={`${isCurrActive ? "opened" : "closed"} icon`} />{thread.owner}</li>
          <li className="created-at"><EventSharpIcon fontSize="small" className={`${isCurrActive ? "opened" : "closed"} icon`} />{moment(thread.created_at).format('YYYY-MM-DD hh:mm')}</li>
          <li><ForumSharpIcon fontSize="small" className={`${isCurrActive ? "opened" : "closed"} icon`} />{thread.comments_num}</li>
        </ul>
      </div>
    </div>
  );
}
