import * as React from 'react';
import { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useEffect } from 'react';
import "./style.css";

export default function ParallelTabs({ allTabs, setData }) {
  const [value, setValue] = useState(0);

  useEffect(() => {
    if (value > 0) {
      setData(allTabs[value]) 
      setValue(0);
    }
  },[value])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '90%', marginRight: 'auto', marginLeft: 'auto'}}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto">
            {
                allTabs.map((tab, index) => (
                  <Tab sx={{color: tab.has_children ? "#00000099" : "#CECED0"}} label={tab.ceh_id ? `${tab.ceh_id} - ${tab.category_name}` : tab.category_name} id={index} key={index}/>))
            }
        </Tabs>
      </Box>
    </Box>
  );
}