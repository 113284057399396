import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './style.css';
import '../../mainStyle.css'
import useProjectStore from "../../stores/useProjectStore";
import { FilledButton } from '../../styles/ButtonStyles';
import FileContainer from '../FileContainer';
import ControlHeader from '../ControlHeader';
import AppFileToMaintainer from '../AppFileToMaintainer';
import ThreadList from '../ThreadList';
import ErrorMessage from '../ErrorMessage';
import useViewWidthStore from '../../stores/useViewWidthStore';

// Icons
import BarChartIcon from '@mui/icons-material/BarChart';

export default function ProjectDetailsMaintainer() {
    const navigate = useNavigate();
    const {projectid} = useParams();
    const selectedProject = useProjectStore((state) => state.selectedProject);
    const [errorMessage, setErrorMessage] = useState("");
    const updateViewWidth = useViewWidthStore((state) => state.updateViewWidth);

    useEffect(() => {
        updateViewWidth('60%');
    },[])

    const loadStatistics = () => {
        if (selectedProject.projectfile_set.length === 0) {
          setErrorMessage('A projekthez nincs feltöltve árazatlan költségvetés.')
        } else {
          navigate(`/dashboard/${selectedProject.id}`);
        }
    }


  return (
    <div className="projectdetailsmaintainer"> 
        <div className="projectdetailsmaintainer-header">
            <BarChartIcon className="stats icon" onClick={() => {loadStatistics()}} />
            <ControlHeader title={selectedProject.name} url="/" />
            <ErrorMessage errorMessage={errorMessage} setErrorMessage={setErrorMessage}/>
        </div>
        <div className='projectdetailsmaintainer-content'>
            <div className='description'>
                <p className='subtitle'>Leírás</p>
                <p>{selectedProject.description}</p>
            </div>
            <div className="files">
                <p className='subtitle'>Fájlok</p>
                {selectedProject.projectfile_set &&
                    <ul>
                        {selectedProject.projectfile_set.map(file => (
                            <li key={file.id}>
                                <FileContainer file={file} setErrorMessage={setErrorMessage} data-testid="projectfile" />
                                {file.projectapplicationfile_set &&
                                    <ul>
                                        {file.projectapplicationfile_set.map( appFile => (
                                            <li key={appFile.id}>
                                                <AppFileToMaintainer file={appFile} setErrorMessage={setErrorMessage} />
                                            </li>
                                        ))}
                                    </ul>
                                }
                            </li>
                        ))}
                    </ul>
                }
            </div>
        </div>
        <div className="projectdetailsmaintainer-control">
            <FilledButton variant="outlined" onClick={() => navigate(`/project-edit/${projectid}`)}>
                Projekt szerkesztése
            </FilledButton> 
        </div>
        <ThreadList projectId={projectid}/>
    </div>
  )
}
