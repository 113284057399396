import React from 'react';
import useDialogStore from '../../stores/useDialogStore';
import WarningDialog from '../WarningDialog';

export default function WarningDialogControl() {
    const dialogStore = useDialogStore();

    return (
        <>
            {dialogStore.openDialog === true &&
                <WarningDialog
                description={dialogStore.dialogMessage}
                setOpen={dialogStore.setOpenDialog}
                />
            }
        </>
    )
}