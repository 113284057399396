import React from 'react'
import { useContext } from 'react';
import AuthContext from '../../context/AuthContext';
import useProjectStore from '../../stores/useProjectStore';
import { IconButton } from '../../styles/ButtonStyles';
import { formDataRequest } from '../../utils/requestUtils';
import './style.css';

// Icons
import AddIcon from '@mui/icons-material/Add';
import AutorenewIcon from '@mui/icons-material/Autorenew';

// Stores
import useFileStore from '../../stores/useFileStore';

export default function UploadFile({
    api, projectFileId = null, editAppFile = false, setErrorMessage = null
}) {
    const { authTokens } = useContext(AuthContext);
    const selectedProject = useProjectStore((state) => state.selectedProject);
    const fileStore = useFileStore();

    const uploadHandler = (event) => {
        const file = event.target.files[0];
        newProjectFile(file);
    }

    const newProjectFile = async (file) => {
        const formData = new FormData();
        if (projectFileId) {
            formData.append("project_file_id", projectFileId);
        }
        else {
            formData.append("project_id", selectedProject.id);
        }
        formData.append("file", file);
        const resp = await formDataRequest(api, 'POST', formData, authTokens.access, setErrorMessage);

        if (resp !== "err" && !resp.error) {
            if (projectFileId) resp.projectFileId = projectFileId;
            fileStore.setServerResp(resp);
        }
    }

  return (
        <IconButton variant="outlined">
            {
                editAppFile === true ? (
                    <AutorenewIcon className="add-icon"/>
                ) : (
                    <AddIcon className="add-icon"/>
                )
            }
            <input id="1" data-testid="upload-input" onChange={(e)=>uploadHandler(e)} type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" variant="outlined" color="success"/>
        </IconButton> 
  )
}