import React from 'react';
import { useState, useEffect, useContext } from 'react';
import AuthContext from '../../../context/AuthContext';
import ControlHeader from '../../ControlHeader';
import useProjectStore from "../../../stores/useProjectStore";
import Parallel from '../Parallel';
import BarChart from '../BarChart';
import ErrorMessage from '../../ErrorMessage';
import DashboardTabs from '../DashboardTabs';
import SumBarChart from '../SumBarChart';
import useViewWidthStore from '../../../stores/useViewWidthStore';
import { IconButton } from '../../../styles/ButtonStyles';
import { getFile, downloadFile } from "../../../utils/fileDownloadUtils";
import { URL } from '../../../constans';
import './style.css';

// Icons
import FileDownloadSharpIcon from '@mui/icons-material/FileDownloadSharp';

export default function Dashboard() {
  const [errorMessage, setErrorMessage] = useState("");
  const { authTokens } = useContext(AuthContext);
  const projectStore = useProjectStore();
  const [target, setTarget] = useState(projectStore.selectedProject.role === "handler" ? projectStore.selectedProject.projectfile_set[0].filename : {filename: "Összesítő"});
  const updateViewWidth = useViewWidthStore((state) => state.updateViewWidth)
  const [allTabs, setAllTabs] = useState(projectStore.selectedProject.role === "handler"
      ? projectStore.selectedProject.projectfile_set
      : [{filename: "Összesítő"}, ...projectStore.selectedProject.projectfile_set]
  );

  useEffect(() => {
    updateViewWidth('95%');
  },[])

  return (
    <div className='dashboard'>
      <div className='dashboard-header'>
        <ControlHeader title={`${projectStore.selectedProject.name} statisztikái`} url="/" />
      </div>
      <div className="dashboard-content">
        {projectStore.selectedProject.role !== "client" &&
          <DashboardTabs allTabs={allTabs} setData={(data) => {setTarget(data)}}/>
        }
        <ErrorMessage errorMessage={errorMessage} setErrorMessage={setErrorMessage} />
        {target && target.filename === "Összesítő" &&
        <>
          <div className="download-btn-container">
            <IconButton variant="outlined">
                <FileDownloadSharpIcon onClick={
                    async (e) =>{
                        const blobRes = await getFile(
                            `${URL}/api/projects/downloads?project_id=${projectStore.selectedProject.id}`,
                            authTokens.access,
                            setErrorMessage
                        );
                        if (blobRes) downloadFile(blobRes, "Összesítő.zip");

                    }
                }/>
            </IconButton>
            <span>Összes fájl letöltése</span>
          </div>
          <SumBarChart projectId={projectStore.selectedProject.id} />
        </>
        }
        {target && target.id &&
          <>
            <BarChart 
              projectId={projectStore.selectedProject.id}
              target={target}
              direction="vbar"
            />
            <Parallel
              projectId={projectStore.selectedProject.id}
              target={target}
            />
            <BarChart 
              projectId={projectStore.selectedProject.id}
              target={target}
              direction="hbar"
            />
          </>
        }
      </div>
    </div>
  );
}
