import React from 'react';
import { FilledButton } from '../../../styles/ButtonStyles';
import './style.css';

// Icons
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';

export default function BackAndForwardBtns({ setFunction = null, navigateBack, navigateForward, backText = "Vissza", forwardText = "Tovább" }) {

    return (
        <div className="back-forward-btns">
            <FilledButton data-testid="back-btn-id" variant="outlined" onClick={() => {navigateBack()}}>
                {
                    backText === "Vissza" ? (
                        <ArrowBackIosNewIcon />
                    ) : (
                        <CloseIcon sx={{marginRight: '3px'}} />
                    )
                }
                {backText}
            </FilledButton> 
            <FilledButton data-testid="forward-btn-id" variant="outlined" onClick={() => {setFunction(); if (navigateForward) navigateForward()}}>
                {forwardText}
                {
                    forwardText === "Tovább" ? (
                        <ArrowForwardIosIcon />
                    ) : (
                        <SaveIcon sx={{marginLeft: '3px'}} />
                    )
                }
            </FilledButton> 
        </div>
    )
}