import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { dataRequest } from '../../../utils/requestUtils';
import AuthContext from '../../../context/AuthContext';
import useProjectStore from '../../../stores/useProjectStore';
import useFileStore from '../../../stores/useFileStore';
import Switch from '@mui/material/Switch';
import './style.css';
import BackAndForwardBtns from '../BackAndForwardBtns';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import DefaultDataCols from '../DefaultDataCols';
import UniqueDataCols from '../UniqueDataCols';
import FileUploadingStatus from '../../FileUploadingStatus';
import ErrorMessage from '../../ErrorMessage';
import { URL } from '../../../constans';

export default function AddExcelSheetsDetails() {
  const { authTokens } = useContext(AuthContext);
  const {projectid} = useParams();
  const navigate = useNavigate();
  const projectStore = useProjectStore();
  const fileStore = useFileStore();
  const [projectFileId, setProjectFileId] = useState(null);
  const [allSheetNames, setAllSheetNames] = useState([]);
  const [newFileName, setNewFileName] = useState("");
  const [files, setFiles] = useState(projectStore.selectedProject.projectfile_set);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (!fileStore.serverResp) {
      navigate("/");
    } else {
      setProjectFileId(fileStore.serverResp.data.project_file_id);
      setNewFileName(fileStore.serverResp.data.project_filename);
      setAllSheetNames(fileStore.serverResp.data.sheet_names);
    }
  },[])
  
  useEffect(() => {
    if (allSheetNames.length > 0) {
      let targetSheets = [];
      fileStore.targetSheetsCheckList.map((isSheetSelected, i) => {
        if (isSheetSelected) {
            const currSheetName = allSheetNames[i];
            if (!fileStore.selectedSheetsData || !fileStore.isUniqueDataCols) {
              addDefTargetSheet(currSheetName, targetSheets);
            }
            else {
              const selectedSheetData = fileStore.selectedSheetsData.find(sheet => sheet.sheet_name === currSheetName);
              if (selectedSheetData) {
                targetSheets.push(selectedSheetData);
              } else {
                addDefTargetSheet(currSheetName, targetSheets);
              }
            }
        }
      });
      fileStore.setSelectedSheetsData(targetSheets)
    }
  },[allSheetNames, fileStore.isUniqueDataCols])

  const addDefTargetSheet = (currSheetName, targetSheets) => {
    targetSheets.push(
      {
        sheet_name: currSheetName,
        data_cols: {
          ceh_id: fileStore.serverResp.data.data_cols.ceh_id,
          description: fileStore.serverResp.data.data_cols.description,
          quantity: fileStore.serverResp.data.data_cols.quantity,
          unit: fileStore.serverResp.data.data_cols.unit,
          material_unit_price: fileStore.serverResp.data.data_cols.material_unit_price,
          fee_unit_price: fileStore.serverResp.data.data_cols.fee_unit_price
        }
      }
    );
  }

  const newProjectFileParse = async () => {
    const projectFileData = {"project_id": projectid, "project_file_id": projectFileId, "main_categories": fileStore.mainCategories, "target_sheets": fileStore.selectedSheetsData};
    setFiles(files => [...files, {id: projectFileId, filename: newFileName}]);
    const resp = await dataRequest(`${URL}/api/projects/files/parse/`, 'POST', projectFileData, authTokens.access, setErrorMessage);
    if (resp !== "err" && !resp.error) {
      if (fileStore.fileIsChanged) {
        const replaceResp = await sendReplaceRequest();
        if (replaceResp !== "err" && !replaceResp.error) {
          let updatedSelectedProject = projectStore.selectedProject;
          updatedSelectedProject.projectfile_set = updatedSelectedProject.projectfile_set.map(file => {
            if (file.id === fileStore.prevProjectFileId) {
              return {id: fileStore.serverResp.data.project_file_id, filename: fileStore.serverResp.data.project_filename};
            } else {
              return file;
            }
          });
          projectStore.setSelectedProject(updatedSelectedProject);
          navigate(`/maintainer-files-edit/${projectid}`);
          cleanFileStore();
        }
      } else {
        projectStore.setSelectedProject({...projectStore.selectedProject, projectfile_set: [...projectStore.selectedProject.projectfile_set, {id: fileStore.serverResp.data.project_file_id, filename: fileStore.serverResp.data.project_filename}]});
        navigate(`/maintainer-files-edit/${projectid}`);
        cleanFileStore();
      }
    }
  }

  const sendReplaceRequest = async () => {
    const requestData = {"project_file_from_id": fileStore.prevProjectFileId, "project_file_to_id": fileStore.serverResp.data.project_file_id};
    setFiles(files => [...files, {id: projectFileId, filename: newFileName}]);
    const resp = await dataRequest(`${URL}/api/projects/files/replace/`, 'POST', requestData, authTokens.access, setErrorMessage);
    return resp;
  }

  const cleanFileStore = () => {
    fileStore.setServerResp(null);
    fileStore.setMainCategories(null);
    fileStore.setTargetSheetsCheckList(null);
    fileStore.setSelectedSheetsData(null);
    fileStore.setFileIsChanged(false);
    fileStore.setPrevProjectFileId(null);
  }

  return (
    <>
    {fileStore.serverResp &&
      <div>
        <h2 className='title'>Sheetek adatainak megadása</h2>
        <ErrorMessage errorMessage={errorMessage} setErrorMessage={setErrorMessage}/>
        <div className='main-container'>
          <div className='description'>
              <p>
                A költségvetési tételeket tartalmazó munkalapok struktúrájának meghatározása az oszlopok segítségével.
                <br />
                <br />
                <b>Egységes</b> esetben minden kiválasztott munkalapot az adott struktúra szerint dolgozunk fel.
                <br />
                <b>Egyedi</b> esetben a kiválasztott munkalapok struktúrái külön-külön beállíthatóak.
              </p>
          </div>
          <div className="sheets">
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>Egységes</Typography>
            <Switch
              color="info"
              checked={fileStore.isUniqueDataCols}
              onChange={() => fileStore.setIsUniqueDataCols(!fileStore.isUniqueDataCols)}
            />
            <Typography>Egyedi</Typography>
          </Stack>
          {fileStore.selectedSheetsData &&
            <div className='excel-data-container'>
              <>
                {fileStore.isUniqueDataCols ? (
                  <>
                    {fileStore.selectedSheetsData.map((sheet, index) => (
                        <UniqueDataCols key={index} index={index}/>
                    ))}
                  </>
                ) : (
                  <DefaultDataCols />
                )
                }
              </>
            </div>
          }
          </div>
          <BackAndForwardBtns
            setFunction={() => {setAllSheetNames([]); newProjectFileParse()}}
            navigateBack={() => navigate(`/select-excel-sheets/${projectid}`)}
            navigateForward={null} forwardText="Mentés"
          />
          <FileUploadingStatus numOfStages={3} activeStage={3}/>
        </div>
      </div>
    }
    </>
  )
}
