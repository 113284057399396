import React from 'react';
import useFileStore from '../../../stores/useFileStore';
import { TextField } from '@mui/material';

export default function UniqueDataCols({ index }) {
    const fileStore = useFileStore();

    const updateTextFieldChange = (e, objectKey) => {
        let newArr = [...fileStore.selectedSheetsData];
        newArr[index].data_cols[objectKey] = e.target.value;
        fileStore.setSelectedSheetsData(newArr);
    }

    return (
        <div>
            <p><b>{fileStore.selectedSheetsData[index].sheet_name}</b></p>
            <TextField 
                id="1"
                value={fileStore.selectedSheetsData[index].data_cols.ceh_id}
                onChange={(e) => {updateTextFieldChange(e, "ceh_id")}}
                label="CÉH id"
                variant="outlined"
                color="info"
                sx={{width: '18.5%', marginRight: '10px', marginBottom: '10px'}}
            />
            <TextField 
                id="2"
                value={fileStore.selectedSheetsData[index].data_cols.ceh_label}
                onChange={(e) => {updateTextFieldChange(e, "ceh_label")}}
                label="CÉH label"
                variant="outlined"
                color="info"
                sx={{width: '18.5%', marginRight: '10px', marginBottom: '10px'}}
            />
            <TextField 
                id="3"
                value={fileStore.selectedSheetsData[index].data_cols.description}
                onChange={(e) => {updateTextFieldChange(e, "description")}}
                label="Költségvetési tétel"
                variant="outlined"
                color="info"
                sx={{width: '18.5%', marginRight: '10px', marginBottom: '10px'}}
            />
            <TextField 
                id="4"
                value={fileStore.selectedSheetsData[index].data_cols.quantity}
                onChange={(e) => {updateTextFieldChange(e, "quantity")}}
                label="Mennyiség"
                variant="outlined"
                color="info"
                sx={{width: '18.5%', marginRight: '10px', marginBottom: '10px'}}
            />
            <TextField 
                id="5"
                value={fileStore.selectedSheetsData[index].data_cols.unit}
                onChange={(e) => {updateTextFieldChange(e, "unit")}}
                label="Egység"
                variant="outlined"
                color="info"
                sx={{width: '18.5%', marginRight: '10px', marginBottom: '10px'}}
            />
            <TextField 
                id="6"
                value={fileStore.selectedSheetsData[index].data_cols.material_unit_price}
                onChange={(e) => {updateTextFieldChange(e, "material_unit_price")}}
                label="Anyag egységár"
                variant="outlined"
                color="info"
                sx={{width: '18.5%', marginRight: '10px', marginBottom: '10px'}}
            />
            <TextField 
                id="7"
                value={fileStore.selectedSheetsData[index].data_cols.fee_unit_price}
                onChange={(e) => {updateTextFieldChange(e, "fee_unit_price")}}
                label="Díj egységár"
                variant="outlined"
                color="info"
                sx={{width: '18.5%', marginRight: '10px', marginBottom: '10px'}}
            />
        </div>
    )
}
