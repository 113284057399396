import React, { useContext, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { FilledButton } from '../styles/ButtonStyles';
import AuthContext from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import ErrorMessage from '../components/ErrorMessage';

export default function LoginPage() {
  const { loginUser } = useContext(AuthContext);
  const { authTokens } = useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (authTokens) navigate("/");
  },[])

  const handleLogin = async (e) => {
    e.preventDefault();
    const resp = await loginUser(e.target.email.value, e.target.password.value,);
    if (resp === "err" || resp.error) {
      setErrorMessage(resp.error.meta.detail)
    }
  }

  return (
    <>
      {!authTokens &&
        <div className="login-page">
          <div className="login-page-content" style={{position: "relative", paddingTop: "20px"}}>
            <form onSubmit={handleLogin} style={{textAlign: 'center', marginTop: '10%', marginBottom: '10%'}}>
            <ErrorMessage errorMessage={errorMessage} setErrorMessage={setErrorMessage}/>
            <TextField type="email" name="email" placeholder="test@example.com" label="Email" variant="outlined" color="info" sx={{m: '10px 20px 10px 0', width: '300px', marginLeft: '20%', marginRight: '20%'}} />
            <TextField type="password" name="password" placeholder="password" label="Jelszó" variant="outlined" color="info" sx={{m: '10px 20px 10px 0', width: '300px', marginLeft: '20%', marginRight: '20%'}} />
            <FilledButton variant="outlined" style={{marginTop: '10px', marginLeft: '20%', marginRight: '20%'}}>
              <input type="submit" style={{opacity: '0', position: 'absolute'}}/>
              Küldés
            </FilledButton>
            </form>
          </div>
        </div>
      }
    </>
  );
}
