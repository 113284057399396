import { huHU } from '@mui/x-data-grid';

export const datagridLocalization = (from, to, count) => {
    return {...huHU.components.MuiDataGrid.defaultProps.localeText,
        MuiTablePagination: {
          labelDisplayedRows: ({ from, to, count }) =>`${from}-${to} / ${count}`,
        }}
}

export const fetchData = async (endpoint, storeFunction, accessData = null, setErrorMessage) => {
    try {
        const headers = {}
        if (accessData) {
            headers['Authorization'] = `Bearer ${accessData}`;
        }

        const resp = await fetch(`${endpoint}`, {
            headers: headers
        });
        const data = await resp.json();
        if (resp.ok) {
            if (storeFunction) {storeFunction(data.data.projects);}
            return data;
        } else {
            handleErrorMessage(data, setErrorMessage)
            return data;
        }
    }
    catch(err) {
        handleErrorMessage(err, setErrorMessage)
        return "err";
    }
}

export const dataRequest = async (endpoint, method, requestData, accessData = null, setErrorMessage) => {
    try {
        const headers = {}
        if (accessData) {
            headers['Authorization'] = `Bearer ${accessData}`;
        }
        const resp = await fetch(`${endpoint}`, {
        method: method,
        headers: headers,
        body: JSON.stringify(requestData),
        });
        const data = await resp.json();
        if (resp.ok) {
            return data;
        }
        else {
            handleErrorMessage(data, setErrorMessage)
            return data;
        }
    }
    catch(err) {
        handleErrorMessage(err, setErrorMessage)
        return "err";
    }
}

export const formDataRequest = async (endpoint, method, formData, accessData = null, setErrorMessage) => {
    try {
        const headers = {}
        if (accessData) {
            headers['Authorization'] = `Bearer ${accessData}`;
        }
        const resp = await fetch(`${endpoint}`, {
        method: method,
        headers: headers,
        body: formData,
        });
        const data = await resp.json();
        if (resp.ok) {
            return data;
        }
        else {
            handleErrorMessage(data, setErrorMessage)
            return data;
        }
    }
    catch(err) {
        handleErrorMessage(err, setErrorMessage)
        return "err";
    }
}

const handleErrorMessage = async (data, setErrorMessage) => {
    if(data.error && data.error.meta.detail) {
        if (setErrorMessage) setErrorMessage(data.error.meta.detail);
    }
    else {
        if (setErrorMessage) setErrorMessage('A kért tartalom a szerveren történt hiba miatt nem elérhető');
    }
};
