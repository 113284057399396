import React from 'react';
import './mainStyle.css';
import ProjectDetailsMaintainer from './components/ProjectDetailsMaintainer';
import ProjectDetailsApplicant from './components/ProjectDetailsApplicant';
import ProjectEditApplicant from './components/ProjectEditApplicant/ProjectEditApplicant';
import ProjectEditMaintainer from './components/ProjectEditMaintainer';
import PageHeader from './components/PageHeader';
import ProjectFilesEditMaintainer from './components/ProjectFilesEditMaintainer';
import AddTargetSheets from './components/ProjectFileUploading/AddTargetSheets';
import SelectExcelSheets from './components/ProjectFileUploading/SelectExcelSheets';
import AddExcelSheetsDetails from './components/ProjectFileUploading/AddExcelSheetsDetails';
import WarningDialogControl from './components/WarningDialogControl';
import Dashboard from './components/Statistics/Dashboard';

import { Routes, Route } from 'react-router-dom';

import LoginPage from './pages/LoginPage';
import ProjectsHomePage from './pages/ProjectsHomePage';

import { AuthProvider } from './context/AuthContext';
import PrivateRoute from './utils/PrivateRoute';

import useViewWidthStore from './stores/useViewWidthStore';

function App() {
  const viewWidth = useViewWidthStore((state) => state.viewWidth)

  return (
    <div>
      <AuthProvider>
        <PageHeader />
        <div className='container' style={{width: viewWidth}}>
          <WarningDialogControl />
          <Routes>
            <Route
              element={
                <PrivateRoute>
                  <ProjectsHomePage />
                </PrivateRoute>
              }
              path="/"
              exact
            />
            <Route
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
              path="/dashboard/:projectid"
              exact
            />
            <Route
              element={
                <PrivateRoute>
                  <ProjectDetailsMaintainer />
                </PrivateRoute>
              }
              path="/project-details-maintainer/:projectid"
              exact
            />
            <Route
              element={
                <PrivateRoute>
                  <ProjectDetailsApplicant />
                </PrivateRoute>
              }
              path="/project-details-applicant/:projectid"
              exact
            />
            <Route
              element={
                <PrivateRoute>
                  <ProjectEditMaintainer />
                </PrivateRoute>
              }
              path="/project-edit/:projectid"
              exact
            />
            <Route
              element={
                <PrivateRoute>
                  <ProjectFilesEditMaintainer />
                </PrivateRoute>
              }
              path="/maintainer-files-edit/:projectid"
              exact
            />
            <Route
              element={
                <PrivateRoute>
                  <AddTargetSheets />
                </PrivateRoute>
              }
              path="/add-target-sheets/:projectid"
              exact
            />
            <Route
              element={
                <PrivateRoute>
                  <SelectExcelSheets />
                </PrivateRoute>
              }
              path="/select-excel-sheets/:projectid"
              exact
            />
            <Route
              element={
                <PrivateRoute>
                  <AddExcelSheetsDetails />
                </PrivateRoute>
              }
              path="/excel-sheets-details/:projectid"
              exact
            />
            <Route
              element={
                <PrivateRoute>
                  <ProjectEditApplicant />
                </PrivateRoute>
              }
              path="/applicant-files-edit/:projectid"
              exact
            />
            <Route element={<LoginPage />} path="/login" />
          </Routes>
        </div>
      </AuthProvider>
    </div>
  );
}

export default App;
