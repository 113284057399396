import React from 'react'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import useFileStore from '../../../stores/useFileStore';
import { IconButton } from '../../../styles/ButtonStyles';
import './style.css';
import '../../../mainStyle.css'
import BackAndForwardBtns from '../BackAndForwardBtns';
import FileUploadingStatus from '../../FileUploadingStatus';
import MultipleSelect from '../../MultipleSelect';
import useProjectStore from '../../../stores/useProjectStore';

// Icons
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

export default function AddTargetSheets() {
    const {projectid} = useParams();
    const navigate = useNavigate();
    const fileStore = useFileStore();
    const [mainCategories, setMainCategories] = useState(fileStore.mainCategories?.length > 0
        ? fileStore.mainCategories
        : [{category_name: "", category_handlers: []}]
    );
    const selectedProject = useProjectStore((state) => state.selectedProject);
    
    const updateTextFieldChange = (e, index) => {
        let newArr = [...mainCategories];
        newArr[index].category_name = e.target.value;
        setMainCategories(newArr);
    }

    const cleanFileStore = () => {
        fileStore.setServerResp(null);
        fileStore.setMainCategories(null);
        fileStore.setTargetSheetsCheckList(null);
        fileStore.setSelectedSheetsData(null);
        fileStore.setFileIsChanged(false);
        fileStore.setPrevProjectFileId(null);
    }

    const removeLastTextField = () => {
        let mainCategoriesArr = [...mainCategories];
        if (mainCategoriesArr.length > 1) {
            mainCategoriesArr.pop();
            setMainCategories(mainCategoriesArr);
        }
    }

    const updateMainCategories = (isCategoryHandlerAdded, item, index) => {
        let updatedMainCategories = [...mainCategories];
        if (isCategoryHandlerAdded) {
            updatedMainCategories[index] = {
                category_name: updatedMainCategories[index].category_name,
                category_handlers: [
                    ...updatedMainCategories[index].category_handlers,
                    {id: item.id}
                ]
            };
        } else {
            updatedMainCategories[index] = {
                category_name: updatedMainCategories[index].category_name,
                category_handlers: updatedMainCategories[index].category_handlers.filter(handler => handler.id !== item.id)
            };
        }
        setMainCategories(updatedMainCategories);
    }

    return (
        <>
            <h2 className='title'>Főkategóriák megadása</h2>
            <div className="main-container">
                <div className='description'>
                    <p>
                        A feltöltendő árazatlan költségvetéshez tartozó főkategóriák megadása, melyek szerint csoportosításra kerülnek a költségvetési tételek (pl. Építészet).
                        <br />
                        <br />
                        Legalább egy főkategória megadása <b>kötelező</b>.
                        <br />
                        <br />
                        Szükség esetén további főkategóriákat <b>hozzáadni</b> a <b>[ + ]</b>, <b>törölni</b> a <b>[ - ]</b> használatával tudunk.
                    </p>
                </div>
                {
                    mainCategories.map((mainCategory, index) => {
                        const listItems = selectedProject.handlers.map(handler =>{
                            return {
                                name: handler.email,
                                id: handler.id,
                                listName: handler.email
                            }
                        });

                        const defSelectedItems = mainCategory.category_handlers?.length > 0
                            ? mainCategory.category_handlers.map(cat => cat.email)
                            : [];

                        return (
                            <div key={index}>
                                <TextField
                                    key={`input-${index}`}
                                    id={`${index}`}
                                    className='main-category'
                                    value={mainCategory.category_name}
                                    onChange={(e) => updateTextFieldChange(e, index)}
                                    label={`${index + 1}. Főkategória`}
                                    variant="outlined"
                                    color="info"
                                    sx={{marginLeft: '25%', marginTop: '10px', width: '50%'}}
                                />
                                <MultipleSelect
                                    key={`select-${index}`}
                                    listItems={listItems}
                                    defSelectedItems={defSelectedItems}
                                    label={`${index + 1}. Főkategória szakági felelősei`}
                                    addToList={(item) => {updateMainCategories(true, item, index)}}
                                    removeFromList={(item) => {updateMainCategories(false, item, index)}}
                                    selectWidth="50%"
                                />
                            </div>
                        )
                    })
                }
                <div className="main-category-btn-group">
                    <IconButton
                        data-testid="add-btn-id"
                        variant="outlined"
                        onClick={() => {setMainCategories(mainCategories => [...mainCategories, {category_name: "", category_handlers: []}])}}
                    >
                        <AddIcon className="add-icon"/>
                    </IconButton> 
                    <IconButton
                        data-testid="remove-btn-id"
                        variant="outlined"
                        onClick={() => {removeLastTextField()}}
                    >
                        <RemoveIcon className="add-icon"/>
                    </IconButton> 
                </div>
                <BackAndForwardBtns
                    setFunction={() => fileStore.setMainCategories(mainCategories.filter(mainCategory => mainCategory !== ''))}
                    navigateBack={() => {navigate(`/maintainer-files-edit/${projectid}`); cleanFileStore()}}
                    navigateForward={() => navigate(`/select-excel-sheets/${projectid}`)} backText="Mégse"
                />
                <FileUploadingStatus numOfStages={3} activeStage={1}/>
            </div>
        </>
    )
}
