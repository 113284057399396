import React from 'react'
import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import AuthContext from '../../context/AuthContext';
import './style.css';
import '../../mainStyle.css';
import { dataRequest } from '../../utils/requestUtils';
import FileContainer from '../FileContainer';
import ControlHeader from '../ControlHeader';
import ErrorMessage from '../ErrorMessage';
import { URL } from '../../constans';

// Stores
import useProjectStore from "../../stores/useProjectStore";
import useFileStore from '../../stores/useFileStore';

export default function ProjectEditApplicant() {
    const { authTokens } = useContext(AuthContext);
    const {projectid} = useParams();
    const selectedProject = useProjectStore((state) => state.selectedProject);
    const setSelectedProject = useProjectStore((state) => state.setSelectedProject);
    const fileStore = useFileStore();
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
      editProjectFileSet("delete error", null);
      async function parseData() {
        const response = await applicationFileParse(fileStore.serverResp.data.project_application_file_id,);
        if (!response.error && response !== "err") {
            editProjectFileSet(null, fileStore.serverResp);
        }
      }
      if (fileStore.serverResp != null) {
        parseData();
        fileStore.setServerResp(null);
      }
    },[fileStore.serverResp])
      
    const editProjectFileSet = (errorMsg, serverResp) => {
      const new_project_file_set = selectedProject.projectfile_set.map(file => {
          if (serverResp !== null  && serverResp.projectFileId === file.id && errorMsg == null) {
              const applicationFile = {
                  id: serverResp.data.project_application_file_id,
                  filename: serverResp.data.project_application_filename,
              }
              file.projectapplicationfile_set[0] = applicationFile;
          }
          else if (errorMsg === "delete error") {
              if (file.error) file.error = "";
          }
          else {
              file.error = errorMsg;
          }
          return file;
      });
      setSelectedProject({...selectedProject, projectfile_set: new_project_file_set})
  }

  const applicationFileParse = async (fileId) => {
      const applicationFileData = {"project_application_file_id": fileId};
      const resp = await dataRequest(`${URL}/api/projects/applications/files/parse/`, 'POST', applicationFileData, authTokens.access, setErrorMessage);
      if (resp.error) editProjectFileSet(resp.error.meta.detail, null);
      return resp;
  }

  return (
    <>
        <ControlHeader title="Fájlok szerkesztése" url={`/project-details-applicant/${projectid}`} />
        <div className='main-container'>
          <ErrorMessage errorMessage={errorMessage} setErrorMessage={setErrorMessage}/>
          <div className="files">
            <p className='subtitle'>Fájlok</p>
            <ul>
              {selectedProject.projectfile_set.map(file => (
                  <li key={file.id}>
                    <FileContainer file={file} editAppFile={true} setErrorMessage={setErrorMessage}/>
                  </li>
              ))}
            </ul>
          </div>
        </div>
    </>
  )
}
