import React from 'react';
import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import useFileStore from '../../../stores/useFileStore';
import BackAndForwardBtns from '../BackAndForwardBtns';
import './style.css';
import '../../../mainStyle.css';
import FileUploadingStatus from '../../FileUploadingStatus';

export default function SelectExcelSheets() {
    const {projectid} = useParams();
    const navigate = useNavigate();
    const [sheetNames, setSheetNames] = useState([]);
    const [checkList, setCheckList] = useState(null);
    const fileStore = useFileStore();

    useEffect(() => {
        setSheetNames(fileStore.serverResp.data.sheet_names);
        if (fileStore.targetSheetsCheckList) {
            setCheckList(fileStore.targetSheetsCheckList)
        } else {
            setCheckList(new Array(fileStore.serverResp.data.sheet_names.length).fill(true));
        }
    },[])

    const handleOnChange = (position) => {
        const updatedCheckList = checkList.map((item, index) =>
          index === position ? !item : item
        );
        setCheckList(updatedCheckList);
      };

    return (
        <>
            <h2 className='title'>Sheetek kiválasztása</h2>
            <div  className='main-container'>
                <div className='description'>
                    <p>
                        A költségvetési tételeket és <b>CÉH id</b>-kat tartalmazó munkalapok kiválasztása.
                    </p>
                </div>
                <div className='sheet-container'>
                <ul>
                    {sheetNames.map((sheet, index) => (
                        <li key={sheet}>
                            <FormControlLabel
                            value="end"
                            control={<Switch color="info" checked={checkList[index]} onChange={() => handleOnChange(index)}/>}
                            label={sheet}
                            labelPlacement="end"
                            style={{display: 'block', fontSize: '2px'}}
                            />
                        </li>
                    ))}
                </ul>
                </div>
                < BackAndForwardBtns setFunction={() => fileStore.setTargetSheetsCheckList(checkList)} navigateBack={() => navigate(`/add-target-sheets/${projectid}`)} navigateForward={() => navigate(`/excel-sheets-details/${projectid}`)}/>
                <FileUploadingStatus numOfStages={3} activeStage={2}/>
            </div>
        </>
    )
}
