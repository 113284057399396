import create from 'zustand';
import { persist } from "zustand/middleware"

let store = (set) => ({
    projectsData: [],
    setProjectsData:  (data) => {
        set({ projectsData: data })
    },
    selectedProject: {},
    setSelectedProject: (data) => {
        set({ selectedProject: data })
    },
});

store = persist(store, { name: "projectStore" });

const useProjectStore = create(store);

export default useProjectStore;
