import React from 'react';
import { useContext } from 'react'
import { getFile, downloadFile } from "../../utils/fileDownloadUtils";
import AuthContext from '../../context/AuthContext';
import { IconButton } from '../../styles/ButtonStyles';
import './style.css';
import moment from 'moment/moment';
import { URL } from '../../constans';

// Icons
import FileDownloadSharpIcon from '@mui/icons-material/FileDownloadSharp';
import AttachFileIcon from '@mui/icons-material/AttachFile';

export default function AppFileToMaintainer({ file, setErrorMessage }) {
    const { authTokens } = useContext(AuthContext);

  return (
    <>
        <span className='applicant-name'>Pályázó: {file.owner}</span>
        <div className='main-file-container'>
            <AttachFileIcon sx={{marginTop: "10px", color: "rgba(115, 120, 121, 0.5)"}}/>
            <div key={file.id} className='applicant-file-container'>
                <div className="file-data">
                    <span>{file.filename}</span>
                    <span className="upload-date">{moment(file.uploaded_at).format('YYYY-MM-DD hh:mm')}</span>
                </div>
                <div className="icons-container">
                    <IconButton variant="outlined">
                        <FileDownloadSharpIcon onClick={
                            async (e) =>{
                                const blobRes = await getFile(
                                    `${URL}/api/projects/applications/files/downloads/${file.id}`,
                                    authTokens.access,
                                    setErrorMessage
                                );
                                if (blobRes) downloadFile(blobRes, file.filename);

                            }
                        }/>
                    </IconButton>
                </div>
            </div>
        </div>
    </>
  )
}
