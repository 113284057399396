import React from 'react'
import { useContext } from 'react';
import AuthContext from '../../context/AuthContext';
import useProjectStore from '../../stores/useProjectStore';
import useFileStore from '../../stores/useFileStore';
import { IconButton } from '../../styles/ButtonStyles';
import { fetchData, formDataRequest } from '../../utils/requestUtils';
import { URL } from '../../constans';
import './style.css';

// Icons
import AutorenewIcon from '@mui/icons-material/Autorenew';

export default function ChangeProjectFile({ api, prevFile, setErrorMessage = null }) {
    const { authTokens } = useContext(AuthContext);
    const selectedProject = useProjectStore((state) => state.selectedProject);
    const fileStore = useFileStore();

    const uploadHandler = (event) => {
        const file = event.target.files[0];
        newProjectFile(file);
    }

    const newProjectFile = async (file) => {
        const formData = new FormData();
        formData.append("project_id", selectedProject.id);
        formData.append("file", file);
        const resp = await formDataRequest(api, 'POST', formData, authTokens.access, setErrorMessage);
        if (resp !== "err" && !resp.error) {;
            const prevFileResp = await setPreviousFileData(resp);
            if (prevFileResp !== "err" && !prevFileResp.error) {
                fileStore.setServerResp(resp);
                fileStore.setPrevProjectFileId(prevFile.id);
            }
        }
    }

    const setPreviousFileData = async(resp) => {
        const data = await fetchData(`${URL}/api/projects/files/${prevFile.id}/`, null, authTokens.access, setErrorMessage);

        if (resp !== "err" && !resp.error) {
            fileStore.setMainCategories(data.data.project_file_data.main_categories);
            fileStore.setSelectedSheetsData(data.data.project_file_data.target_sheets);
            let selectedSheets = [];
            for (const target_sheet of data.data.project_file_data.target_sheets) {
                selectedSheets.push(target_sheet.sheet_name);
            }
            let checkList = [];
            for (const sheet_name of resp.data.sheet_names) {
                if (selectedSheets.includes(sheet_name)) {
                    checkList.push(true);
                } else {
                    checkList.push(false);
                }
            }
            fileStore.setTargetSheetsCheckList(checkList);
            fileStore.setFileIsChanged(true);
        }
        return data;
    }

  return (
        <IconButton variant="outlined">
            <AutorenewIcon className="add-icon"/>
            <input id="1" data-testid="upload-input" onChange={(e)=>uploadHandler(e)} type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" variant="outlined" color="success"/>
        </IconButton> 
  )
}
