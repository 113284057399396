import { createContext, useState } from "react";
import jwt_decode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { URL } from "../constans";

const AuthContext = createContext();

export default AuthContext;

export function AuthProvider({ children }) {
  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem('authTokens')
    ? JSON.parse(localStorage.getItem('authTokens'))
    : null
  );
  const [accessData, setAccessData] = useState(() =>
    localStorage.getItem('authTokens')
    ? jwt_decode(JSON.parse(localStorage.getItem('authTokens')).access)
    : null
  );

  const navigate = useNavigate();

  const loginUser = async (email, password) => {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(
        {
          'email': email,
          'password': password,
        }
      )
    }

    const response = await fetch(`${URL}/api/authentication/token`, options);
    const data = await response.json();

    if (!response.ok) {
      return data;
    }

    setAuthTokens(data);
    localStorage.setItem('authTokens', JSON.stringify(data));

    setAccessData(jwt_decode(data.access));

    navigate('/');
    return data;
  }

  const logoutUser = () => {
    setAuthTokens(null);
    setAccessData(null);
    localStorage.removeItem('authTokens');
  };

  const contextData = {
    loginUser,
    logoutUser,
    accessData,
    authTokens,
  };

  return (
    <AuthContext.Provider value={contextData}>
      {children}
    </AuthContext.Provider>
  );
}
