import React, { useEffect } from 'react'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import './style.css';
import '../../mainStyle.css'
import UploadFile from '../UploadFile';
import FileContainer from '../FileContainer';
import ControlHeader from '../ControlHeader';
import ErrorMessage from '../ErrorMessage';
import { URL } from '../../constans';

// Stores
import useProjectStore from '../../stores/useProjectStore';
import useFileStore from '../../stores/useFileStore';

export default function ProjectFilesEditMaintainer() {
    const {projectid} = useParams();
    const navigate = useNavigate();
    const projectStore = useProjectStore();
    const fileStore = useFileStore();
    const [fileIsCleaned, setFileIsCleaned] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        cleanFileStore();
    },[])

    useEffect(() => {
        if (fileStore.serverResp && fileIsCleaned) {
            navigate(`/add-target-sheets/${projectid}`)
        }
    },[fileStore.serverResp])

    const cleanFileStore = () => {
        fileStore.setServerResp(null);
        fileStore.setMainCategories(null);
        fileStore.setTargetSheetsCheckList(null);
        fileStore.setSelectedSheetsData(null);
        fileStore.setFileIsChanged(false);
        fileStore.setPrevProjectFileId(null);
        setFileIsCleaned(true);
    }

    return (
        <>
            <ControlHeader title="Fájlok szerkesztése" url={`/project-edit/${projectid}`} />
            <ErrorMessage errorMessage={errorMessage} setErrorMessage={setErrorMessage}/>
            <div className='main-container'>
                <div className="files">
                    <p className='subtitle'>Fájlok</p>
                    <ul>
                        {projectStore.selectedProject.projectfile_set && projectStore.selectedProject.projectfile_set.map((file, i) => (
                            <li key={i}>
                                <FileContainer file={file} projectFileInactive={true} projectFileChange={true} setErrorMessage={setErrorMessage}/>
                            </li>
                        ))}
                    </ul>
                    <UploadFile api={`${URL}/api/projects/files/`} setErrorMessage={setErrorMessage}/>
                </div>
            </div>
        </>
    )
}
