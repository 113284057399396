import React from 'react'
import { useContext, useEffect } from 'react'
import { getFile, downloadFile } from "../../utils/fileDownloadUtils";
import AuthContext from '../../context/AuthContext';
import { IconButton } from '../../styles/ButtonStyles';
import './style.css';
import ChangeProjectFile from '../ChangeProjectFile';
import { dataRequest } from '../../utils/requestUtils';
import { URL } from '../../constans';

// Icons
import FileDownloadSharpIcon from '@mui/icons-material/FileDownloadSharp';
import RemoveIcon from '@mui/icons-material/Remove';

// Stores
import useProjectStore from '../../stores/useProjectStore';
import useDialogStore from '../../stores/useDialogStore';

export default function ProjectFile({ file, inactiveIcon = false, changeIcon = false, setErrorMessage = null }) {
    const { authTokens } = useContext(AuthContext);
    const projectStore = useProjectStore();
    const dialogStore = useDialogStore();

    useEffect(() => {
      if (dialogStore.isConfirmed && dialogStore.itemIdToDelete === file.id) {
        archiveProjectFile();
        dialogStore.setIsConfirmed(false);
        dialogStore.setItemIdToDelete(null);
      }
    }, [dialogStore.isConfirmed])
    

    const archiveProjectFile = async () => {
        const data = {"project_file_id": file.id};
        const resp = await dataRequest(`${URL}/api/projects/files/archive/`, 'POST', data, authTokens.access, setErrorMessage);
        if (resp !== "err" && !resp.error) {
            const updatedProject = projectStore.selectedProject;
            updatedProject.projectfile_set = updatedProject.projectfile_set.filter(currFile => currFile.id !== file.id);
            projectStore.setSelectedProject(updatedProject);
        }
    }

    return (
        <div key={file.id} className='file-container' style={{ border: projectStore.selectedProject.role === "maintainer" ? "solid #00b4d84d" : "solid #73787933"}}>
            <p>{file.filename}</p>
            <div className="icons-container">
                {inactiveIcon && 
                    <IconButton variant="outlined" onClick={() => {dialogStore.showDialogMessage("Biztos, hogy arhciválni szeretné a fájlt?"); dialogStore.setItemIdToDelete(file.id)}}>
                        <RemoveIcon/>
                    </IconButton>
                }
                {changeIcon && 
                    <ChangeProjectFile api={`${URL}/api/projects/files/`} prevFile={file} setErrorMessage={setErrorMessage}/>
                }
                <IconButton variant="outlined">
                    <FileDownloadSharpIcon  onClick={
                        async (e) => {
                            const blobRes = await getFile(
                                `${URL}/api/projects/files/downloads/${file.id}`,
                                authTokens.access,
                                setErrorMessage
                            );
                            if (blobRes) downloadFile(blobRes, file.filename);
                        }
                    }/>
                </IconButton>
            </div>
        </div>
    )
}
