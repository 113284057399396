import React, { useState, useEffect, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/hu';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import AuthContext from '../../context/AuthContext';
import { FilledButton } from '../../styles/ButtonStyles';
import TextField from '@mui/material/TextField';
import './style.css';
import '../../mainStyle.css'
import useProjectStore from '../../stores/useProjectStore';
import ControlHeader from '../ControlHeader';
import { dataRequest } from '../../utils/requestUtils';
import ErrorMessage from '../ErrorMessage';
import { IconButton } from '../../styles/ButtonStyles';
import useDialogStore from '../../stores/useDialogStore';
import { URL } from '../../constans';

//Icons
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SaveIcon from '@mui/icons-material/Save';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import StopIcon from '@mui/icons-material/Stop';

export default function ProjectEditMaintainer() {
    const navigate = useNavigate();
    const { authTokens } = useContext(AuthContext);
    const projectStore = useProjectStore();
    const {projectid} = useParams();
    const [description, setDescription] = useState(projectStore.selectedProject.description);
    const [name, setName] = useState(projectStore.selectedProject.name);
    const [dueDate, setDueDate] = useState(projectStore.selectedProject.due_date);
    const [reminders, setReminders] = useState(projectStore.selectedProject.reminders);
    const [state, setState] = useState(projectStore.selectedProject.state);
    const [nextState, setNextState] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const dialogStore = useDialogStore();

    const updateProjectData = async () => {
        const projectData = {"project_name": name, "project_description": description, "due_date": dueDate, "reminders": reminders, "state": state}
        const data = await dataRequest(`${URL}/api/projects/${projectid}/`, 'PUT', projectData, authTokens.access, setErrorMessage);
        if (data !== "err" && !data.error) {
            const updatedProject = projectStore.selectedProject;
            updatedProject.name = data.project_name;
            updatedProject.description = data.project_description;
            updatedProject.due_date = data.due_date;
            updatedProject.reminders = data.reminders;
            projectStore.setSelectedProject(data)
            
            const updatedProjectsData = projectStore.projectsData.map(project => {
                if (project.id === updatedProject.id) {
                    return updatedProject;
                } else {
                    return project;
                }
            });
            projectStore.setProjectsData(updatedProjectsData);
        }
    }

    useEffect(() => {
        if (dialogStore.isConfirmed) {
            if (nextState) setState(nextState)
            dialogStore.setIsConfirmed(false);
        }
      }, [dialogStore.isConfirmed])

  return (
    <>
        <ControlHeader title="Projekt szerkesztése" url={`/project-details-maintainer/${projectid}`} />
        <ErrorMessage errorMessage={errorMessage} setErrorMessage={setErrorMessage}/>
        <div className='main-container'>
            <div className="project-details">
                <div className='publish-btns'>
                <IconButton
                    variant="outlined"
                    className={`${state !== "play" && 'light-button'}`}
                    onClick={() => {dialogStore.showDialogMessage("Biztos, hogy szeretné nyitottra állítani a projektet? Ha igen, kattintson majd az adatok mentése gombra a végleges mentéshez."); setNextState("play")}}
                >
                    <PlayArrowIcon />
                </IconButton> 
                <IconButton
                    variant="outlined"
                    className={`${state !== "pause" && 'light-button'}`}
                    onClick={() => {dialogStore.showDialogMessage("Biztos, hogy szeretné szüneteltetni a projektet? Ha igen, kattintson majd az adatok mentése gombra a végleges mentéshez."); setNextState("pause")}}
                >
                    <PauseIcon />
                </IconButton> 
                <IconButton
                    variant="outlined"
                    className={`${state !== "stop" && 'light-button'}`}
                    onClick={() => {dialogStore.showDialogMessage("Biztos, hogy szeretné zártra állítani a projektet? Ha igen, kattintson majd az adatok mentése gombra a végleges mentéshez."); setNextState("stop")}}
                >
                    <StopIcon />
                </IconButton> 
                </div>
                <TextField id="0" className='edit-input' value={name} onChange={(e) => setName(e.target.value)} label="Projektnév" multiline variant="outlined" color="info" sx={{  margin: '10px 20px 10px 0', width: '100%', marginLeft: '10%'}}/>
                <TextField id="1" className='edit-input' value={description} onChange={(e) => setDescription(e.target.value)} label="Leírás"multiline variant="outlined" color="info" sx={{  margin: '10px 20px 10px 0', width: '100%', marginLeft: '10%'}}/>
            </div>
                <div style={{display: 'flex', marginLeft: 'auto', marginRight: 'auto', width: '80%', justifyContent: 'center'}}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                            <MobileDatePicker
                                locale="hu"
                                id="2"
                                label="Határidő"
                                inputFormat="YYYY-MM-DD hh:mm"
                                toolbarFormat="MMMM DD hh:mm"
                                value={dueDate}
                                onChange={(e) => setDueDate(moment(e).format("YYYY-MM-DD hh:mm"))}
                                renderInput={(params) => <TextField {...params} color="success" sx={{m: '10px 10px 10px 0', width: '260px'}}/>}
                                variant="outlined"
                            />
                        </LocalizationProvider>
                    <TextField id="3" value={reminders} onChange={(e) => setReminders(e.target.value)} label="Emlékeztetők" placeholder='Pl: 2, 5, 6' multiline variant="outlined" color="info" sx={{ margin: '10px 0px 10px 10px', width: '260px' }}/>
                </div>
            <div className="btn-group">
            <FilledButton variant="outlined" onClick={() => updateProjectData()} sx={{width: '225px'}}>
                    <SaveIcon sx={{marginRight: '5px'}} />
                    Adatok mentése
                </FilledButton> 
                <FilledButton variant="outlined" onClick={() => navigate(`/maintainer-files-edit/${projectid}`)} sx={{width: '225px'}}>
                    Fájlok szerkesztése
                    <ArrowForwardIosIcon sx={{marginLeft: '3px'}} />
                </FilledButton> 
            </div>
        </div>
    </>
  )
}
