import * as React from 'react';
import { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import MultipleSelect from '../../MultipleSelect';
import { FilledButton } from '../../../styles/ButtonStyles';
import moment from 'moment';
import useFileStore from '../../../stores/useFileStore';
import './style.css';

// Icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function AppFilesSelect({ projectApplicants, handleData }) {
  const [expanded, setExpanded] = useState(false);
  const fileStore = useFileStore();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className='appfilesselect'>
        <div className="appfilesselect-content">
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography sx={{ width: '33%' }}>
                    Pályázati fájlok
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <div className="select-container">
                    {
                        projectApplicants.map((projectApplicant, i) => {
                            const listItems = projectApplicant.project_application_files
                                ? projectApplicant.project_application_files.map(appFile => {
                                    return {
                                        name: appFile.project_application_filename,
                                        id: appFile.project_application_file_id,
                                        listName: `${appFile.project_application_filename} - ${moment(appFile.uploaded_at).format("YYYY-MM-DD hh:mm")}`
                                    }
                                })
                                : [];

                            const defSelectedItems = projectApplicant.project_application_files?.length > 0 
                                ? [projectApplicant.project_application_files[0].project_application_filename]
                                : "";

                            return (
                                <MultipleSelect
                                    key={i}
                                    listItems={listItems}
                                    defSelectedItems={defSelectedItems}
                                    label={projectApplicant.username}
                                    addToList={(item) => {fileStore.addSelectedAppFileId(item.id)}}
                                    removeFromList={(item) => {fileStore.removeSelectedAppFileId(item.id)}}
                                    selectWidth="98%"
                                />
                            )
                        })
                    }
                </div>
                <div className="generate-btn-container">
                    <FilledButton variant="outlined" onClick={handleData}>
                        Újragenerálás
                    </FilledButton> 
                </div>
            </AccordionDetails>
        </Accordion>
        </div>
    </div>
  );
}