import React from 'react';
import moment from 'moment/moment';
import './style.css';

// icons
import PortraitSharpIcon from '@mui/icons-material/PortraitSharp';
import EventSharpIcon from '@mui/icons-material/EventSharp';

export default function ThreadComment({ index, user, text, createdAt, isActive }) {

  return (
    <div className="thread-comment">
      <div className="thread-comment-header">
        <ul>
          <li><PortraitSharpIcon fontSize="small" className={`icon ${isActive ? "opened" : "closed"}`} />{user}</li>
          <li><EventSharpIcon fontSize="small" className={`icon ${isActive ? "opened" : "closed"}`} />{moment(createdAt).format('YYYY-MM-DD hh:mm')}</li>
        </ul>
      </div>
      <div className="thread-comment-content">
        {text}
      </div>
    </div>
  );
}
