import React from 'react';
import { useEffect, useState, useContext, useLayoutEffect } from 'react';
import ParallelTabs from '../ParallelTabs';
import Breadcrumb from '../../Breadcrumb';
import Plot from 'react-plotly.js';
import AuthContext from '../../../context/AuthContext';
import { fetchData } from '../../../utils/requestUtils';
import useFileStore from '../../../stores/useFileStore';
import Switch from '../../Switch';
import RadioButtonGroup from '../RadioButtonGroup';
import PlotPercentInputs from '../PlotPercentInputs';
import CheckboxList from '../CheckboxList';
import AppFilesSelect from '../AppFilesSelect';
import { DesignedLabel } from '../../../styles/LabelStyles';
import ErrorMessage from '../../ErrorMessage';
import { URL } from '../../../constans';
import './style.css';

export default function Parallel({ projectId, target }) {
    const { authTokens } = useContext(AuthContext);
    const [errorMessage, setErrorMessage] = useState("");
    const [currDimensions, setCurrDimensions] = useState([]);
    const [categories, setCategories] = useState([]);
    const [currTab, setCurrTab] = useState(null);
    const [breadcrumbLinks, setBreadcrumbLinks] = useState([{category_name: 'Projekt'}]);
    const [projectApplicants, setProjectApplicants] = useState(null);
    const fileStore = useFileStore();
    const [unshownPlotLines, setUnshownPlotLines] = useState([]);
    const [isMixedSourcesAllowed, setIsMixedSourcesAllowed] = useState(false);
    const [priceType, setPriceType] = useState("total");
    const [statLines, setStatLines] = useState();
    const [lineColouring, setLineColouring] = useState({});
    const [selectedFunction, setSelectedFunction] = useState("");
 
    const trace = {
        type: 'parcoords',
        line: lineColouring,
        dimensions: currDimensions
    };

    useLayoutEffect(() => {
        if (target) {
            loadData();
        }
    },[target])

    const loadData = async () => {
        let reqURL = `${URL}/api/projects/applications/files?project_id=${projectId}`;
        const resp = await fetchData(reqURL, false, authTokens.access, setErrorMessage);

        if (resp !== "err" && !resp.error) {
            setProjectApplicants(resp.data.applications);
            let defProjectAppFileIds = [];
            for (const application of resp.data.applications) {
                if (application.project_application_files.length > 0) {
                    defProjectAppFileIds.push(application.project_application_files[0].project_application_file_id);
                }
            }

            fileStore.setSelectedAppFileIds(defProjectAppFileIds);
            fetchPlotData(defProjectAppFileIds);
        }
    }

    useEffect(() => {
        handleDataChanges();
    },[currTab])

    const handleDataChanges = async () => {
        if (currTab) {
            const resp = await refreshPlot(currTab);
            if (resp !== "err" && !resp.error) {
                if (!breadcrumbLinks.some(link => link.category_name === currTab.category_name)) {
                    setBreadcrumbLinks([...breadcrumbLinks, currTab])
                }
            }
        }
    }

    const refreshPlot = async (currTab, refreshedPriceType = priceType) => {
        let reqURL = `${URL}/api/stats/parallel?project_id=${projectId}`
            + `&project_file_id=${target.id}`
            + `&project_application_file_ids=${fileStore.selectedAppFileIds.join(",")}`
            + `&price=${refreshedPriceType}`
            + `&allow_mixed_sources=${!isMixedSourcesAllowed}`;

        if (currTab && currTab.ceh_id) {
            reqURL += `&ceh_id=${currTab.ceh_id}`;
        }
        const resp = await fetchData(reqURL, false, authTokens.access, setErrorMessage);
        if (resp !== "err" && !resp.error) {
            setCurrDimensions(resp.data.dimensions);
            setStatLines(resp.data.legend_data);
            if (currTab && currTab.category_name) setCategories([{category_name: `${currTab.category_name}`}, ...resp.data.categories])
            setLineColouring(resp.data.lines);
        }
        return resp;
    }

    const fetchPlotData = async (defProjectAppFileIds) => {
        const resp = await fetchData(
            `${URL}/api/stats/parallel?project_id=${projectId}`
                + `&project_file_id=${target.id}`
                + `&project_application_file_ids=${defProjectAppFileIds.join(",")}`
                + `&price=${priceType}`
                + `&allow_mixed_sources=${isMixedSourcesAllowed}`,
            false,
            authTokens.access,
            setErrorMessage
        );
        if (resp !== "err" && !resp.error) {
            setCurrDimensions(resp.data.dimensions);
            setStatLines(resp.data.legend_data);
            setCategories([{category_name: 'Projekt'}, ...resp.data.categories]);
            setLineColouring(resp.data.lines);
        }
    }

    const handleConstraintrange = (isInverse, topPercent, bottomPercent) => {
        const FunctionLine = statLines.find(line => line.name === selectedFunction.name);
        const FunctionVal = currDimensions[currDimensions.length-2].values[FunctionLine.index];
        const topNum = FunctionVal * (100 + topPercent) / 100;
        const bottomNum = FunctionVal * (100 - bottomPercent) / 100;

        setCurrDimensions(currDimensions.map((column, index) => {
            if (index === currDimensions.length-2) {
                const newCol = {};
                newCol.range = column.range;
                newCol.label = column.label;
                newCol.values = column.values;
                if (isInverse) {
                    newCol.constraintrange = [];
                    newCol.constraintrange.push([topNum, column.range[1]]);
                    newCol.constraintrange.push([column.range[0], bottomNum]);
                } else {
                    newCol.constraintrange = [bottomNum, topNum];
                }
                return newCol;
            } else {
                return column;
            }
        }))
    }

    const handleLineChanges = (toAddLine, line) => {
        if (toAddLine) {
            addPlotLine(line.index, unshownPlotLines.find(currLine => currLine.index === line.index).values)
        } else {
            deletePlotLine(line.index);
        }
    }
    
    const deletePlotLine = (lineIndex) => {
        let refreshedDimensions = JSON.parse(JSON.stringify(currDimensions));
        const lineToDelete = {index: lineIndex, values: []};

        refreshedDimensions = refreshedDimensions.map(column =>  {
            lineToDelete.values.push(column.values[lineIndex])
            let newCol = column;
            newCol.values[lineIndex] = -1;
            return newCol;
        })
        setCurrDimensions(refreshedDimensions);
        setUnshownPlotLines(unshownPlotLines => [...unshownPlotLines, lineToDelete])
    }
    
    const addPlotLine = (lineIndex, lineToAdd) => {
        let refreshedDimensions = JSON.parse(JSON.stringify(currDimensions));
        refreshedDimensions = refreshedDimensions.map((column, i) => {
            let newCol = column;
            newCol.values[lineIndex] = lineToAdd[i];
            return newCol;
        })
        setCurrDimensions(refreshedDimensions)
        setUnshownPlotLines(unshownPlotLines => {return unshownPlotLines.filter(plotLine => plotLine.index !== lineToAdd.index)})
    }

    const handleExtremeCherrypick = async () => {
        await refreshPlot(currTab);
        setIsMixedSourcesAllowed(isMixedSourcesAllowed => !isMixedSourcesAllowed)
    }

    const handlePriceTypeChange = async (refreshedPriceType) => {
        await refreshPlot(currTab, refreshedPriceType);
        setPriceType(refreshedPriceType)
    }
    
  return (
    <div className="parallel" sx={{position: 'relative'}}>
        <div className="parallel-header">
            <ParallelTabs allTabs={categories} setData={(data) => {setCurrTab(data)}}/>
            {breadcrumbLinks.length > 0 &&
                <Breadcrumb sx={{paddingTop: '30px'}} links={breadcrumbLinks} refetchData={refreshPlot} setBreadcrumbLinks={setBreadcrumbLinks} setCurrTab={setCurrTab} />
            }
            <ErrorMessage errorMessage={errorMessage} setErrorMessage={setErrorMessage} />
        </div>
        <div className="parallel-content">
            <div className="parallel-config">
                <DesignedLabel sx={{marginLeft: '5%'}}>Cherry-pick</DesignedLabel>
                <Switch handleData={handleExtremeCherrypick} leftText="min(A+D)" rightText="min(A) + min(D)" />
                <RadioButtonGroup
                    labelName="Ártípus kiválasztása"
                    controlItems={[{value: "total", name: "Teljes ár (A+D)"}, {value: "material", name: "Anyagár (A)"}, {value: "fee", name: "Munkadíj (D)"}]}
                    handleData={handlePriceTypeChange}
                />
                <CheckboxList listItems={statLines} handleData={handleLineChanges} label="Megjelenített vonalak" />
            </div>
            <Plot
            data = {[trace]}
            useResizeHandler
            className='plot'
            />
        </div>
        <PlotPercentInputs handleData={handleConstraintrange} statLines={statLines} selectedFunction={selectedFunction} setSelectedFunction={setSelectedFunction} />
        <div className="parallel-control">
            {projectApplicants &&
                <AppFilesSelect projectApplicants={projectApplicants} handleData={() =>{fetchPlotData(fileStore.selectedAppFileIds)}} />
            }
        </div>
    </div>
  )
}
