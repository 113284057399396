import React, { useState, useContext } from 'react';
import building from "../../assets/building.png";
import AuthContext from '../../context/AuthContext';
import './style.css';

// Icons
import PortraitSharpIcon from '@mui/icons-material/PortraitSharp';
import LogoutSharpIcon from '@mui/icons-material/LogoutSharp';

export default function PageHeader() {
  const { accessData, logoutUser } = useContext(AuthContext);
  const [title, setTitle] = useState("TENDERPLATFORM");

  return (
    <div className='page-header'>
      <nav className="page-header-header">
        <h3>
          {title} &nbsp;|
          </h3>
          <h3>
          <PortraitSharpIcon className="icon page-header-icon"/>
          {accessData && accessData.username} &emsp;
          {accessData && (
            <LogoutSharpIcon
              onClick={() => logoutUser()}
              className="icon page-header-icon"
            />
          )}
        </h3>
      </nav>
      <div className="page-header-content">
        <div className="background-container">
          <img
            src={building}
            alt=""
            className='background-img'
          />
        </div>
      </div>
    </div>
  );
}
