import React from 'react';

import CircleIcon from '@mui/icons-material/Circle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import './style.css';
import { useState, useEffect } from 'react';

export default function FileUploadingStatus({numOfStages, activeStage}) {
    const [allStages, setAllStages] = useState([]);

    useEffect(() => {
        let stages = Array(numOfStages).fill(false);
        stages[activeStage-1] = true;
        setAllStages(stages);
    },[])

  return (
    <div className="circles-container">
        {
            allStages.map((stage, i) => (
                
                    stage === true ? (
                        <CircleIcon className="icon" key={i}/>
                    ) : (
                        <RadioButtonUncheckedIcon className="icon" key={i}/>
                    )
                
            ))
        }
    </div>
  )
}