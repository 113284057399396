import React from 'react';
import { useState, useContext, useLayoutEffect } from 'react';
import Plot from 'react-plotly.js';
import AuthContext from '../../../context/AuthContext';
import { fetchData } from '../../../utils/requestUtils';
import { URL } from '../../../constans';
import './style.css';

export default function SumBarChart({ projectId, setErrorMessage }) {
    const { authTokens } = useContext(AuthContext);
    const [traces, setTraces] = useState([]);
 
    const data = traces;
    const layout = {title: 'Pályázatok teljes ára'};

    useLayoutEffect(() => {
        fetchPlotData();
    },[])

    const fetchPlotData = async () => {
        const resp = await fetchData(`${URL}/api/stats/project?project_id=${projectId}`,
            false,
            authTokens.access,
            setErrorMessage
        );
        if (resp !== "err" && !resp.error) {
            setTraces(resp.data.traces);
        }
    }
    
  return (
    <div className="sumbarchart">
        <div className="sumbarchart-content">
            <Plot
                data={data}
                layout={layout}
                useResizeHandler
                className='sumbarchart-plot'
            />
        </div>
        <div style={{height: '30px'}}></div>
    </div>
  )
}
