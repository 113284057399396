import create from 'zustand';
import { persist } from "zustand/middleware"

let store = (set) => ({
    serverResp: null,
    setServerResp:  (data) => {
        set({ serverResp: data })
    },
    mainCategories: null,
    setMainCategories: (data) => {
        set({ mainCategories: data })
    },
    targetSheetsCheckList: null,
    setTargetSheetsCheckList: (data) => {
        set({ targetSheetsCheckList: data })
    },
    selectedSheetsData: null,
    setSelectedSheetsData:  (data) => {
        set({ selectedSheetsData: data })
    },
    prevProjectFileId: null,
    setPrevProjectFileId:  (data) => {
        set({ prevProjectFileId: data })
    },
    fileIsChanged: false,
    setFileIsChanged: (data) => {
        set({ fileIsChanged: data })
    },
    isUniqueDataCols: false,
    setIsUniqueDataCols: (data) => {
        set({ isUniqueDataCols: data })
    },
    selectedAppFileIds: [],
    setSelectedAppFileIds: (data) => {
        set({ selectedAppFileIds: data })
    },
    addSelectedAppFileId: (newAppFileId) => set(state => ({
        selectedAppFileIds: [...state.selectedAppFileIds, newAppFileId]
    })),
    removeSelectedAppFileId: (id) =>set(state => ({
        selectedAppFileIds: state.selectedAppFileIds.filter((appFileId) => appFileId !== id)
    })),
});

store = persist(store, { name: "fileStore" });

const useFileStore = create(store);

export default useFileStore;
