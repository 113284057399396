import React from 'react';
import { useState } from 'react';
import ThreadCommentList from '../ThreadCommentList/ThreadCommentList';
import ThreadSummary from '../ThreadSummary';
import ErrorMessage from '../ErrorMessage';

export default function Thread({ thread }) {
  const [isCommentListShowed, setIsCommentListShowed] = useState(false);
  const [isActive, setIsActive] = useState(thread.is_active);
  const [errorMessage, setErrorMessage] = useState("");

  return (
    <div className="thread">
      <div className="thread-content">
        <ErrorMessage errorMessage={errorMessage} setErrorMessage={setErrorMessage}/>
        <ThreadSummary
          thread={thread}
          isCommentListShowed={isCommentListShowed}
          setIsCommentListShowed={setIsCommentListShowed}
          setIsActive={setIsActive}
          setErrorMessage={setErrorMessage}
        />
        { 
          isCommentListShowed &&
          <ThreadCommentList
            threadId={thread.id}
            commentList={thread.comment_set}
            isActive={isActive}
            setErrorMessage={setErrorMessage}
          />
        }
      </div>
    </div>
  );
}
