import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../mainStyle.css'

// Icons
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

export default function ControlHeader({ title, url }) {
    const navigate = useNavigate();

    return (
        <>
            <h2 className='title'>
                <ArrowBackIosNewIcon className="back-icon" onClick={() => navigate(url)}/>
                {title}
            </h2>
        </>
    )
}