import React from 'react';
import { ColouredSwitch } from '../../styles/SwitchStyles';
import Stack from '@mui/material/Stack';

export default function Switch({ handleData, leftText, rightText }) {
  return (
    <Stack direction="row" spacing={1} alignItems="center" onChange={handleData} sx={{paddingTop: '10px', marginLeft: '5px', display: 'inline-block'}}>
        <span style={{fontFamily: "Roboto,Helvetica,Arial,sans-serif"}}>{leftText}</span>
        <ColouredSwitch />
        <span style={{fontFamily: "Roboto,Helvetica,Arial,sans-serif"}}>{rightText}</span>
    </Stack>
  )
}