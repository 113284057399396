import * as React from 'react';
import { useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { DesignedLabel } from '../../../styles/LabelStyles';
import './style.css';

export default function RadioButtonsGroup({ labelName,  controlItems, handleData}) {
    const [value, setValue] = useState(controlItems[0].value);

    const handleChange = (event) => {
      setValue(event.target.value);
      handleData(event.target.value)
    };

    return (
        <FormControl sx={{marginLeft: '5%', paddingTop: '10px', display: 'block'}}>
            <DesignedLabel>{labelName}</DesignedLabel>
            <RadioGroup
                row
                value={value}
                onChange={handleChange}
                sx={{display: 'inline-block'}}
            >
            {controlItems.map((item, i) => (
                <FormControlLabel key={i} value={item.value} control={<Radio />} label={item.name} />
            ))}
            </RadioGroup>
        </FormControl>
    );
}
