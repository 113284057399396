import * as React from 'react';
import './style.css';

// Icons
import CloseIcon from '@mui/icons-material/Close';

export default function ErrorMessage({ errorMessage, setErrorMessage }) {

    return (
        <>
        {errorMessage !== "" &&
            <div className='errormessage'>
                <div className='errormessage-header'>
                    Hiba
                    <CloseIcon className='icon' onClick={() => {setErrorMessage("")}}/>
                </div>
                <div className='errormessage-content'>
                    {errorMessage}
                </div>
            </div>
        }
        </>
    );
}