import React from 'react';
import { useContext } from 'react'
import { getFile, downloadFile } from "../../utils/fileDownloadUtils";
import AuthContext from '../../context/AuthContext';
import { IconButton } from '../../styles/ButtonStyles';
import UploadFile from '../UploadFile';
import { URL } from '../../constans';
import './style.css';

// Icons
import FileDownloadSharpIcon from '@mui/icons-material/FileDownloadSharp';
import AttachFileIcon from '@mui/icons-material/AttachFile';

export default function ProjectApplicantFile({ file, editAppFile, setErrorMessage = null }) {
    const { authTokens } = useContext(AuthContext);

  return (
    <div className='main-file-container'>
        <AttachFileIcon sx={{marginTop: "10px", color: "rgba(115, 120, 121, 0.5)"}}/>
        <div key={file.projectapplicationfile_set[0].id} className='applicant-file-container'>
            <span>{file.projectapplicationfile_set[0].filename}</span>
            <div className="icons-container">
                {editAppFile === true && <UploadFile api={`${URL}/api/projects/applications/files/`} projectFileId={file.id} editAppFile={editAppFile} setErrorMessage={setErrorMessage}/>}
                <IconButton variant="outlined">
                    <FileDownloadSharpIcon onClick={
                        async (e) => {
                            const blobRes = await getFile(
                                `${URL}/api/projects/applications/files/downloads/${file.projectapplicationfile_set[0].id}`,
                                authTokens.access,
                                setErrorMessage
                            );
                            if (blobRes) downloadFile(blobRes, file.projectapplicationfile_set[0].filename);
                        }
                    }/>
                </IconButton>
            </div>
        </div>
    </div>
  )
}
