import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import './style.css';
import '../../mainStyle.css'
import useProjectStore from "../../stores/useProjectStore";
import { FilledButton } from '../../styles/ButtonStyles';
import FileContainer from '../FileContainer';
import ThreadList from '../ThreadList';
import ControlHeader from '../ControlHeader';
import ErrorMessage from '../ErrorMessage';

export default function ProjectDetailsApplicant() {
    const navigate = useNavigate();
    const {projectid} = useParams();
    const selectedProject = useProjectStore((state) => state.selectedProject);
    const [errorMessage, setErrorMessage] = useState("");

  return (
    <> 
        <ControlHeader title={selectedProject.name} url="/" />
        <ErrorMessage errorMessage={errorMessage} setErrorMessage={setErrorMessage} />
        <div className='main-container'>
            <div className='description'>
                <p className='subtitle'>Leírás</p>
                <p>{selectedProject.description}</p>
            </div>
            {
                selectedProject && selectedProject.opened ? (
                    <>
                        <div className="files">
                            <p className='subtitle'>Fájlok</p>
                            <ul>
                                {selectedProject.projectfile_set.map(file => (
                                    <li key={file.id}>
                                        <FileContainer file={file} data-testid="projectfile" setErrorMessage={setErrorMessage}/>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="btn-container">
                            <FilledButton variant="outlined" onClick={() => navigate(`/applicant-files-edit/${projectid}`)}>
                                Pályázat feltöltése
                            </FilledButton> 
                        </div>
                    </>
                ) : (
                    <div className='unavailable'>
                        <p>A fájlok átmenetileg nem elérhetőek.</p>
                    </div>
                )
            }
        </div>
        <ThreadList projectId={projectid}/>
    </>
  )
}
