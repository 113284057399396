import create from 'zustand';

const useDialogStore = create((set) => ({
    dialogMessage: "",
    showDialogMessage: (message) => set(state => ({
        dialogMessage: message,
        openDialog: true
    })),
    openDialog: false,
    setOpenDialog: (value) => set(state => ({
        openDialog: value
    })),
    isConfirmed: false,
    setIsConfirmed: (value) => set(state => ({
        isConfirmed: value
    })),
    itemIdToDelete: null,
    setItemIdToDelete: (value) => set(state => ({
        itemIdToDelete: value
    })),
}));

export default useDialogStore;