import * as React from 'react';
import { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useEffect } from 'react';

export default function DashboardTabs({ allTabs, setData }) {
  const [value, setValue] = useState(0);

  useEffect(() => {
    setData(allTabs[value]) 
  },[value])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '90%', marginRight: 'auto', marginLeft: 'auto', marginTop: '10px', backgroundColor: 'rgb(0, 180, 216, 0.09)', color: 'white', borderRadius: '5px 5px 5px 5px'}}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto">
            {
                allTabs.map((tab, index) => (
                    <Tab label={tab.filename} id={index} key={index}/>))
            }
        </Tabs>
      </Box>
    </Box>
  );
}