import React from 'react';
import useFileStore from '../../../stores/useFileStore';
import { TextField } from '@mui/material';

export default function DefaultDataCols() {
    const fileStore = useFileStore();

    const updateSelectedArray = (objectKey, newVal) => {
        const updatedArr = fileStore.selectedSheetsData.map(sheet => {
            sheet.data_cols[objectKey] = newVal;
            return sheet;
        })
        fileStore.setSelectedSheetsData(updatedArr);
    }
 
    return (
        <div>
            <TextField 
                id="1"
                value={fileStore.selectedSheetsData[0] ? fileStore.selectedSheetsData[0].data_cols.ceh_id : ""}
                onChange={(e) => {updateSelectedArray("ceh_id", e.target.value)}}
                label="CÉH id"
                variant="outlined"
                color="info"
                sx={{width: '18.5%', marginRight: '10px', marginBottom: '10px'}}
            />
            <TextField 
                id="2"
                value={fileStore.selectedSheetsData[0] ? fileStore.selectedSheetsData[0].data_cols.ceh_label : ""}
                onChange={(e) => {updateSelectedArray("ceh_label", e.target.value)}}
                label="CÉH label"
                variant="outlined"
                color="info"
                sx={{width: '18.5%', marginRight: '10px', marginBottom: '10px'}}
            />
            <TextField 
                id="3"
                value={fileStore.selectedSheetsData[0] ? fileStore.selectedSheetsData[0].data_cols.description : ""}
                onChange={(e) => {updateSelectedArray("description", e.target.value)}}
                label="Költségvetési tétel"
                variant="outlined"
                color="info"
                sx={{width: '18.5%', marginRight: '10px', marginBottom: '10px'}}
            />
            <TextField 
                id="4"
                value={fileStore.selectedSheetsData[0] ? fileStore.selectedSheetsData[0].data_cols.quantity : ""}
                onChange={(e) => {updateSelectedArray("quantity", e.target.value)}}
                label="Mennyiség"
                variant="outlined"
                color="info"
                sx={{width: '18.5%', marginRight: '10px', marginBottom: '10px'}}
            />
            <TextField 
                id="5"
                value={fileStore.selectedSheetsData[0] ? fileStore.selectedSheetsData[0].data_cols.unit : ""}
                onChange={(e) => {updateSelectedArray("unit", e.target.value)}}
                label="Egység"
                variant="outlined"
                color="info"
                sx={{width: '18.5%', marginRight: '10px', marginBottom: '10px'}}
            />
            <TextField 
                id="6"
                value={fileStore.selectedSheetsData[0] ? fileStore.selectedSheetsData[0].data_cols.material_unit_price : ""}
                onChange={(e) => {updateSelectedArray("material_unit_price", e.target.value)}}
                label="Anyag egységár"
                variant="outlined"
                color="info"
                sx={{width: '18.5%', marginRight: '10px', marginBottom: '10px'}}
            />
            <TextField 
                id="7"
                value={fileStore.selectedSheetsData[0] ? fileStore.selectedSheetsData[0].data_cols.fee_unit_price : ""}
                onChange={(e) => {updateSelectedArray("fee_unit_price", e.target.value)}}
                label="Díj egységár"
                variant="outlined"
                color="info"
                sx={{width: '18.5%', marginRight: '10px', marginBottom: '10px'}}
            />
        </div>
    )
}
